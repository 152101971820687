:root 
{	
	--fondo-secundario-claro-sp: rgb(163, 203, 187);
	--fondo-sp: rgb(100, 194, 194);
	--fondo-claro-sp: #887c77; /*rgb(114, 104, 100); */
	--fondo-cabeceras-sp: #a59d9a; 
	--fondo-oscuro-sp: rgb(81, 71, 68); 
	--fondo-semi-oscuro-sp: rgb(145, 128, 123);
	--fondo-secundario-sp: rgb(128, 126, 134);
	--fondo-secundario-oscuro-sp: rgb(164, 162, 170);
	--fondo-totalizadores-sp: rgb(223, 206, 201);
	--fondo-secundario-resaltar-sp: rgb(148, 144, 161);
	--botones-sp: coral;
	--botones-over-sp: rgb(81, 71, 68);
	--botones-secundarios-sp: rgb(100, 194, 194);
	--botones-secundarios-hover-sp: rgb(116, 107, 104);
	--grupos-sp: rgb(81, 71, 68);
	--grupos-hover-sp: rgb(102, 99, 96);
	--filas-tabla-sp: rgb(175, 165, 161); /*rgb(206, 158, 140);*/
	--filas-tabla-par-sp: rgb(218, 212, 210); 
}

li
{
    list-style: none;
}

a 
{
    color: black;
}

a:hover 
{
    color: var(--fondo-oscuro-sp);
    text-decoration: none;
}

.clear
{
    clear: both;
}

.btn
{
	display: inline-block;
 	padding: 15px;
 	margin-bottom: 0;
 	font-size: 14px;
 	font-weight: 400;
 	line-height: 1.42857143;
 	text-align: center;
 	white-space: nowrap;
 	vertical-align: middle;
 	-ms-touch-action: manipulation;
 	touch-action: manipulation;
 	cursor: pointer;
 	-webkit-user-select: none;
 	-moz-user-select: none;
 	-ms-user-select: none;
 	user-select: none;
 	background-image: none;
 	border:1px solid transparent;
    border-radius: 4px;
    text-decoration: none; 
    letter-spacing: 3px;
}

div.btn 
{
    padding: 12px 0 12px 0;
}

.btn:focus,.btn:active:focus,.btn.active:focus,.btn.focus,.btn:active.focus,.btn.active.focus
{
	outline:thin dotted;
 	outline:5px auto -webkit-focus-ring-color;
 	outline-offset:-2px
}

.btn:hover,.btn:focus,.btn.focus
{
	color: var(--fondo-oscuro-sp);
 	text-decoration:none
}

.btn:active,.btn.active
{
	background-image:none;
    outline:0;
 	-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);
 	box-shadow:inset 0 3px 5px rgba(0,0,0,.125)
}

.btn.disabled,.btn[disabled],fieldset[disabled] .btn
{
	pointer-events:none;
 	cursor:not-allowed;
 	filter:alpha(opacity=65);
 	-webkit-box-shadow:none;
 	box-shadow:none;
 	opacity:.65
}

.btn-default
{
	background-color: var(--fondo-semi-oscuro-sp);
 	color:#fff;
    border-color:#CCC;
    letter-spacing: 3px;
}

.btn-default:hover,.btn-default:focus,.btn-default.focus,.btn-default:active,.btn-default.active,.open>.dropdown-toggle.btn-default
{
	background-color: var(--fondo-claro-sp);
 	color:#e6e6e6;
    border-color:var(--fondo-claro-sp);
    transition-duration: 0.5s; 
}

.btn-default:active,.btn-default.active,.open>.dropdown-toggle.btn-default
{
	background-image:none
}

.btn-default.disabled,.btn-default[disabled],fieldset[disabled] .btn-default,.btn-default.disabled:hover,.btn-default[disabled]:hover,fieldset[disabled] .btn-default:hover,.btn-default.disabled:focus,.btn-default[disabled]:focus,fieldset[disabled] .btn-default:focus,.btn-default.disabled.focus,.btn-default[disabled].focus,fieldset[disabled] .btn-default.focus,.btn-default.disabled:active,.btn-default[disabled]:active,fieldset[disabled] .btn-default:active,.btn-default.disabled.active,.btn-default[disabled].active,fieldset[disabled] .btn-default.active
{
	background-color:#fff;
 	border-color:#ccc
}

.btn-default .badge
{
	color:#fff;
 	background-color: var(--fondo-oscuro-sp)
}

.btn-primary
{
	color: white;
 	background-color: var(--botones-sp);
    border-color: var(--botones-sp);
}

.btn-secondary
{
    color: white;
    background-color: var(--botones-secundarios-sp);
    border-color: var(--botones-secundarios-sp);
    
	border-radius: 25px !important;
}

.btn-secondary:hover
{
    color: white;
    background-color: var(--botones-secundarios-hover-sp);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary.active,
.open>.dropdown-toggle.btn-secondary
{
	color: white; 
}

.btn-primary-icon
{
    color: var(--fondo-semi-oscuro-sp);
    cursor: pointer;
    transition-duration: 1s;
}

.btn-primary-icon:hover
{
    color: var(--botones-sp);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary
{
	color:#fff;
 	background-color: var(--fondo-oscuro-sp);
    border-color: var(--fondo-oscuro-sp);
    transition-duration: 1s; 
}

.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary
{
    background-image:none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active
{
	background-color: var(--fondo-oscuro-sp);
 	border-color: var(--fondo-sp)
}

.btn-primary .badge
{
	color: var(--fondo-oscuro-sp);
 	background-color:#fff
}

.btn-soft
{
    color: gray;
    background-color: rgb(205, 229, 246);
    border-color: rgb(167, 189, 204);
}

.btn-soft:hover,
.btn-soft:focus,
.btn-soft.focus,
.btn-soft:active,
.btn-soft.active,
.open>.dropdown-toggle.btn-soft
{
    background-color: rgb(181, 211, 233);
    border-color: rgb(149, 174, 190);
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12
{
	min-height:1px;
 	/*padding-right:15px;
 	padding-left:15px*/
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12
{
	float:left
}

.col-xs-12
{
	width:100%
}

.col-xs-11
{
	width:91.66666667%
}

.col-xs-10
{
	width:83.33333333%
}

.col-xs-9
{
	width:75%
}

.col-xs-8
{
	width:66.66666667%
}

.col-xs-7
{
	width:58.33333333%
}

.col-xs-6
{
	width:50%
}

.col-xs-5
{
	width:41.66666667%
}

.col-xs-4
{
	width:33.33333333%
}

.col-xs-3
{
	width:25%
}

.col-xs-2
{
	width:16.66666667%
}

.col-xs-1
{
	width:8.33333333%
}

.col-xs-pull-12
{
	right:100%
}

.col-xs-pull-11
{
	right:91.66666667%
}

.col-xs-pull-10
{
	right:83.33333333%
}

.col-xs-pull-9
{
	right:75%
}

.col-xs-pull-8
{
	right:66.66666667%
}

.col-xs-pull-7
{
	right:58.33333333%
}

.col-xs-pull-6
{
	right:50%
}

.col-xs-pull-5
{
	right:41.66666667%
}

.col-xs-pull-4
{
	right:33.33333333%
}

.col-xs-pull-3
{
	right:25%
}

.col-xs-pull-2
{
	right:16.66666667%
}

.col-xs-pull-1
{
	right:8.33333333%
}

.col-xs-pull-0
{
	right:auto
}

.col-xs-push-12
{
	left:100%
}

.col-xs-push-11
{
	left:91.66666667%
}

.col-xs-push-10
{
	left:83.33333333%
}

.col-xs-push-9
{
	left:75%
}

.col-xs-push-8
{
	left:66.66666667%
}

.col-xs-push-7
{
	left:58.33333333%
}

.col-xs-push-6
{
	left:50%
}

.col-xs-push-5
{
	left:41.66666667%
}

.col-xs-push-4
{
	left:33.33333333%
}

.col-xs-push-3
{
	left:25%
}

.col-xs-push-2
{
	left:16.66666667%
}

.col-xs-push-1
{
	left:8.33333333%
}

.col-xs-push-0
{
	left:auto
}

.col-xs-offset-12
{
	margin-left:100%
}

.col-xs-offset-11
{
	margin-left:91.66666667%
}

.col-xs-offset-10
{
	margin-left:83.33333333%
}

.col-xs-offset-9
{
	margin-left:75%
}

.col-xs-offset-8
{
	margin-left:66.66666667%
}

.col-xs-offset-7
{
	margin-left:58.33333333%
}

.col-xs-offset-6
{
	margin-left:50%
}

.col-xs-offset-5
{
	margin-left:41.66666667%
}

.col-xs-offset-4
{
	margin-left:33.33333333%
}

.col-xs-offset-3
{
	margin-left:25%
}

.col-xs-offset-2
{
	margin-left:16.66666667%
}

.col-xs-offset-1
{
	margin-left:8.33333333%
}

.col-xs-offset-0
{
	margin-left:0
}

@media (min-width:768px)
{
    .col-sm-12
    {
        width:100%
    }

    .col-sm-11
    {
        width:91.66666667%
    }

    .col-sm-10
    {
        width:83.33333333%
    }

    .col-sm-9
    {
        width:75%
    }

    .col-sm-8
    {
        width:66.66666667%
    }

    .col-sm-7
    {
        width:58.33333333%
    }

    .col-sm-6
    {
        width:50%
    }

    .col-sm-5
    {
        width:40%
    }

    .col-sm-4
    {
        width:33.33333333%
    }

    .col-sm-3
    {
        width:25%
    }

    .col-sm-2
    {
        width:16.66666667%
    }

    .col-sm-1
    {
        width:8.33333333%
    }

    .col-sm-pull-12
    {
        right:100%
    }

    .col-sm-pull-11
    {
        right:91.66666667%
    }

    .col-sm-pull-10
    {
        right:83.33333333%
    }

    .col-sm-pull-9
    {
        right:75%
    }

    .col-sm-pull-8
    {
        right:66.66666667%
    }

    .col-sm-pull-7
    {
        right:58.33333333%
    }

    .col-sm-pull-6
    {
        right:50%
    }

    .col-sm-pull-5
    {
        right:41.66666667%
    }

    .col-sm-pull-4
    {
        right:33.33333333%
    }

    .col-sm-pull-3
    {
        right:25%
    }

    .col-sm-pull-2
    {
        right:16.66666667%
    }

    .col-sm-pull-1
    {
        right:8.33333333%
    }

    .col-sm-pull-0
    {
        right:auto
    }

    .col-sm-push-12
    {
        left:100%
    }

    .col-sm-push-11
    {
        left:91.66666667%
    }

    .col-sm-push-10
    {
        left:83.33333333%
    }

    .col-sm-push-9
    {
        left:75%
    }

    .col-sm-push-8
    {
        left:66.66666667%
    }

    .col-sm-push-7
    {
        left:58.33333333%
    }

    .col-sm-push-6
    {
        left:50%
    }

    .col-sm-push-5
    {
        left:41.66666667%
    }

    .col-sm-push-4
    {
        left:33.33333333%
    }

    .col-sm-push-3
    {
        left:25%
    }

    .col-sm-push-2
    {
        left:16.66666667%
    }

    .col-sm-push-1
    {
        left:8.33333333%
    }

    .col-sm-push-0
    {
        left:auto
    }

    .col-sm-offset-12
    {
        margin-left:100%
    }

    .col-sm-offset-11
    {
        margin-left:91.66666667%
    }

    .col-sm-offset-10
    {
        margin-left:83.33333333%
    }

    .col-sm-offset-9
    {
        margin-left:75%
    }

    .col-sm-offset-8
    {
        margin-left:66.66666667%
    }

    .col-sm-offset-7
    {
        margin-left:58.33333333%
    }

    .col-sm-offset-6
    {
        margin-left:50%
    }

    .col-sm-offset-5
    {
        margin-left:41.66666667%
    }

    .col-sm-offset-4
    {
        margin-left:33.33333333%
    }

    .col-sm-offset-3
    {
        margin-left:25%
    }

    .col-sm-offset-2
    {
        margin-left:16.66666667%
    }

    .col-sm-offset-1
    {
        margin-left:8.33333333%
    }

    .col-sm-offset-0
    {
        margin-left:0
    }
}

@media (min-width:992px)
{
    .col-md-12
    {
        width:100%
    }

    .col-md-11
    {
        width:91.66666667%
    }

    .col-md-10
    {
        width:83.33333333%
    }

    .col-md-9
    {
        width:75%
    }

    .col-md-8
    {
        width:66.66666667%
    }

    .col-md-7
    {
        width:58.33333333%
    }

    .col-md-6
    {
        width:50%
    }

    .col-md-5
    {
        width:41.66666667%
    }

    .col-md-4
    {
        width:33.33333333%
    }

    .col-md-3
    {
        width:25%
    }

    .col-md-2
    {
        width:16.66666667%
    }

    .col-md-1
    {
        width:8.33333333%
    }

    .col-md-pull-12
    {
        right:100%
    }

    .col-md-pull-11
    {
        right:91.66666667%
    }

    .col-md-pull-10
    {
        right:83.33333333%
    }

    .col-md-pull-9
    {
        right:75%
    }

    .col-md-pull-8
    {
        right:66.66666667%
    }

    .col-md-pull-7
    {
        right:58.33333333%
    }

    .col-md-pull-6
    {
        right:50%
    }

    .col-md-pull-5
    {
        right:41.66666667%
    }

    .col-md-pull-4
    {
        right:33.33333333%
    }

    .col-md-pull-3
    {
        right:25%
    }

    .col-md-pull-2
    {
        right:16.66666667%
    }

    .col-md-pull-1
    {
        right:8.33333333%
    }

    .col-md-pull-0
    {
        right:auto
    }

    .col-md-push-12
    {
        left:100%
    }

    .col-md-push-11
    {
        left:91.66666667%
    }

    .col-md-push-10
    {
        left:83.33333333%
    }

    .col-md-push-9
    {
        left:75%
    }

    .col-md-push-8
    {
        left:66.66666667%
    }

    .col-md-push-7
    {
        left:58.33333333%
    }

    .col-md-push-6
    {
        left:50%
    }

    .col-md-push-5
    {
        left:41.66666667%
    }

    .col-md-push-4
    {
        left:33.33333333%
    }

    .col-md-push-3
    {
        left:25%
    }

    .col-md-push-2
    {
        left:16.66666667%
    }

    .col-md-push-1
    {
        left:8.33333333%
    }

    .col-md-push-0
    {
        left:auto
    }

    .col-md-offset-12
    {
        margin-left:100%
    }

    .col-md-offset-11
    {
        margin-left:91.66666667%
    }

    .col-md-offset-10
    {
        margin-left:83.33333333%
    }

    .col-md-offset-9
    {
        margin-left:75%
    }

    .col-md-offset-8
    {
        margin-left:66.66666667%
    }

    .col-md-offset-7
    {
        margin-left:58.33333333%
    }

    .col-md-offset-6
    {
        margin-left:50%
    }

    .col-md-offset-5
    {
        margin-left:41.66666667%
    }

    .col-md-offset-4
    {
        margin-left:33.33333333%
    }

    .col-md-offset-3
    {
        margin-left:25%
    }

    .col-md-offset-2
    {
        margin-left:16.66666667%
    }

    .col-md-offset-1
    {
        margin-left:8.33333333%
    }

    .col-md-offset-0
    {
        margin-left:0
    }
}

@media (min-width:1200px)
{
    .col-lg-12
    {
        width:100%
    }

    .col-lg-11
    {
        width:91.66666667%
    }

    .col-lg-10
    {
        width:83.33333333%
    }

    .col-lg-9
    {
        width:75%
    }

    .col-lg-8
    {
        width:66.66666667%
    }

    .col-lg-7
    {
        width:58.33333333%
    }

    .col-lg-6
    {
        width:50%
    }

    .col-lg-5
    {
        width:41.66666667%
    }

    .col-lg-4
    {
        width:33.33333333%
    }

    .col-lg-3
    {
        width:25%
    }

    .col-lg-2
    {
        width:16.66666667%
    }

    .col-lg-1
    {
        width:8.33333333%
    }

    .col-lg-pull-12
    {
        right:100%
    }

    .col-lg-pull-11
    {
        right:91.66666667%
    }

    .col-lg-pull-10
    {
        right:83.33333333%
    }

    .col-lg-pull-9
    {
        right:75%
    }

    .col-lg-pull-8
    {
        right:66.66666667%
    }

    .col-lg-pull-7
    {
        right:58.33333333%
    }

    .col-lg-pull-6
    {
        right:50%
    }

    .col-lg-pull-5
    {
        right:41.66666667%
    }

    .col-lg-pull-4
    {
        right:33.33333333%
    }

    .col-lg-pull-3
    {
        right:25%
    }

    .col-lg-pull-2
    {
        right:16.66666667%
    }

    .col-lg-pull-1
    {
        right:8.33333333%
    }

    .col-lg-pull-0
    {
        right:auto
    }

    .col-lg-push-12
    {
        left:100%
    }

    .col-lg-push-11
    {
        left:91.66666667%
    }

    .col-lg-push-10
    {
        left:83.33333333%
    }

    .col-lg-push-9
    {
        left:75%
    }

    .col-lg-push-8
    {
        left:66.66666667%
    }

    .col-lg-push-7
    {
        left:58.33333333%
    }

    .col-lg-push-6
    {
        left:50%
    }

    .col-lg-push-5
    {
        left:41.66666667%
    }

    .col-lg-push-4
    {
        left:33.33333333%
    }

    .col-lg-push-3
    {
        left:25%
    }

    .col-lg-push-2
    {
        left:16.66666667%
    }

    .col-lg-push-1
    {
        left:8.33333333%
    }

    .col-lg-push-0
    {
        left:auto
    }

    .col-lg-offset-12
    {
        margin-left:100%
    }

    .col-lg-offset-11
    {
        margin-left:91.66666667%
    }

    .col-lg-offset-10
    {
        margin-left:83.33333333%
    }

    .col-lg-offset-9
    {
        margin-left:75%
    }

    .col-lg-offset-8
    {
        margin-left:66.66666667%
    }

    .col-lg-offset-7
    {
        margin-left:58.33333333%
    }

    .col-lg-offset-6
    {
        margin-left:50%
    }

    .col-lg-offset-5
    {
        margin-left:41.66666667%
    }

    .col-lg-offset-4
    {
        margin-left:33.33333333%
    }

    .col-lg-offset-3
    {
        margin-left:25%
    }

    .col-lg-offset-2
    {
        margin-left:16.66666667%
    }

    .col-lg-offset-1
    {
        margin-left:8.33333333%
    }

    .col-lg-offset-0
    {
        margin-left:0
    }
}

table
{
	background-color:transparent
}

caption
{
	padding-top:8px;
 	padding-bottom:8px;
 	color:#777;
 	text-align:left
}

th
{
	text-align:left
}

.table
{
	width:100%;
 	max-width:100%;
 	margin-bottom:20px
}

.table>thead>tr>th,.table>tbody>tr>th,.table>tfoot>tr>th,.table>thead>tr>td,.table>tbody>tr>td,.table>tfoot>tr>td
{
	padding:8px;
 	line-height:1.42857143;
 	vertical-align:top;
 	font-size: 15px;
 	border-top:1px solid #ddd
}

.table>thead>tr>th
{
	vertical-align:bottom;
 	border-bottom:2px solid #ddd;
 	color: white;
 	font-size: 15px;
 	background-color: rgb(11, 189, 165);
 	vertical-align: middle;
}

.table>caption+thead>tr:first-child>th,.table>colgroup+thead>tr:first-child>th,.table>thead:first-child>tr:first-child>th,.table>caption+thead>tr:first-child>td,.table>colgroup+thead>tr:first-child>td,.table>thead:first-child>tr:first-child>td
{
	border-top:0
}

.table>tbody+tbody
{
	border-top:2px solid #ddd
}

.table .table
{
	background-color:#fff
}

.table-condensed>thead>tr>th,.table-condensed>tbody>tr>th,.table-condensed>tfoot>tr>th,.table-condensed>thead>tr>td,.table-condensed>tbody>tr>td,.table-condensed>tfoot>tr>td
{
	padding:5px
}

.table-bordered
{
	border:1px solid #ddd
}

.table-bordered>thead>tr>th,.table-bordered>tbody>tr>th,.table-bordered>tfoot>tr>th,.table-bordered>thead>tr>td,.table-bordered>tbody>tr>td,.table-bordered>tfoot>tr>td
{
	border:1px solid #ddd
}

.table-bordered>thead>tr>th,.table-bordered>thead>tr>td
{
	border-bottom-width:2px
}

.table-striped>tbody>tr:nth-child(odd)
{
	background-color:#f9f9f9
}

.table-hover>tbody>tr:hover
{
	background-color: #DDD;
	font-weight: bold;
}

.table-hover>tbody>tr.Anidado:hover
{
	background-color: white;
}

table col[class*=col-]
{
	position:static;
 	display:table-column;
 	float:none
}

table td[class*=col-],table th[class*=col-]
{
	position:static;
 	display:table-cell;
 	float:none
}

.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th
{
	background-color:#f5f5f5;
}

.table-hover>tbody>tr>td.active:hover,.table-hover>tbody>tr>th.active:hover,.table-hover>tbody>tr.active:hover>td,.table-hover>tbody>tr:hover>.active,.table-hover>tbody>tr.active:hover>th
{
	background-color:#e8e8e8
}

.table>thead>tr>td.success,.table>tbody>tr>td.success,.table>tfoot>tr>td.success,.table>thead>tr>th.success,.table>tbody>tr>th.success,.table>tfoot>tr>th.success,.table>thead>tr.success>td,.table>tbody>tr.success>td,.table>tfoot>tr.success>td,.table>thead>tr.success>th,.table>tbody>tr.success>th,.table>tfoot>tr.success>th
{
	background-color:#dff0d8
}

.table-hover>tbody>tr>td.success:hover,.table-hover>tbody>tr>th.success:hover,.table-hover>tbody>tr.success:hover>td,.table-hover>tbody>tr:hover>.success,.table-hover>tbody>tr.success:hover>th
{
	background-color:#d0e9c6
}

.table>thead>tr>td.info,.table>tbody>tr>td.info,.table>tfoot>tr>td.info,.table>thead>tr>th.info,.table>tbody>tr>th.info,.table>tfoot>tr>th.info,.table>thead>tr.info>td,.table>tbody>tr.info>td,.table>tfoot>tr.info>td,.table>thead>tr.info>th,.table>tbody>tr.info>th,.table>tfoot>tr.info>th
{
	background-color:#d9edf7
}

.table-hover>tbody>tr>td.info:hover,.table-hover>tbody>tr>th.info:hover,.table-hover>tbody>tr.info:hover>td,.table-hover>tbody>tr:hover>.info,.table-hover>tbody>tr.info:hover>th
{
	background-color:#c4e3f3
}

.table>thead>tr>td.warning,.table>tbody>tr>td.warning,.table>tfoot>tr>td.warning,.table>thead>tr>th.warning,.table>tbody>tr>th.warning,.table>tfoot>tr>th.warning,.table>thead>tr.warning>td,.table>tbody>tr.warning>td,.table>tfoot>tr.warning>td,.table>thead>tr.warning>th,.table>tbody>tr.warning>th,.table>tfoot>tr.warning>th
{
	background-color:#fcf8e3
}

.table-hover>tbody>tr>td.warning:hover,.table-hover>tbody>tr>th.warning:hover,.table-hover>tbody>tr.warning:hover>td,.table-hover>tbody>tr:hover>.warning,.table-hover>tbody>tr.warning:hover>th
{
	background-color:#faf2cc
}

.table>thead>tr>td.danger,.table>tbody>tr>td.danger,.table>tfoot>tr>td.danger,.table>thead>tr>th.danger,.table>tbody>tr>th.danger,.table>tfoot>tr>th.danger,.table>thead>tr.danger>td,.table>tbody>tr.danger>td,.table>tfoot>tr.danger>td,.table>thead>tr.danger>th,.table>tbody>tr.danger>th,.table>tfoot>tr.danger>th
{
	background-color:#f2dede
}

.table-hover>tbody>tr>td.danger:hover,.table-hover>tbody>tr>th.danger:hover,.table-hover>tbody>tr.danger:hover>td,.table-hover>tbody>tr:hover>.danger,.table-hover>tbody>tr.danger:hover>th
{
	background-color:#ebcccc
}

.table-responsive
{
	min-height:.01%;
 	overflow-x:auto
}

@media screen and (max-width:767px)
{
	.table-responsive
    {
        width:100%;
        margin-bottom:15px;
        overflow-y:hidden;
        -ms-overflow-style:-ms-autohiding-scrollbar;
        border:1px solid #ddd
    }

    .table-responsive>.table
    {
        margin-bottom:0
    }

    .table-responsive>.table>thead>tr>th,.table-responsive>.table>tbody>tr>th,.table-responsive>.table>tfoot>tr>th,.table-responsive>.table>thead>tr>td,.table-responsive>.table>tbody>tr>td,.table-responsive>.table>tfoot>tr>td
    {
        white-space:nowrap
    }

    .table-responsive>.table-bordered
    {
        border:0
    }

    .table-responsive>.table-bordered>thead>tr>th:first-child,.table-responsive>.table-bordered>tbody>tr>th:first-child,.table-responsive>.table-bordered>tfoot>tr>th:first-child,.table-responsive>.table-bordered>thead>tr>td:first-child,.table-responsive>.table-bordered>tbody>tr>td:first-child,.table-responsive>.table-bordered>tfoot>tr>td:first-child
    {
        border-left:0
    }

    .table-responsive>.table-bordered>thead>tr>th:last-child,.table-responsive>.table-bordered>tbody>tr>th:last-child,.table-responsive>.table-bordered>tfoot>tr>th:last-child,.table-responsive>.table-bordered>thead>tr>td:last-child,.table-responsive>.table-bordered>tbody>tr>td:last-child,.table-responsive>.table-bordered>tfoot>tr>td:last-child
    {
        border-right:0
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>th,.table-responsive>.table-bordered>tfoot>tr:last-child>th,.table-responsive>.table-bordered>tbody>tr:last-child>td,.table-responsive>.table-bordered>tfoot>tr:last-child>td
    {
        border-bottom:0
    }
}
