:root 
{	
	--oscuro-fondo-secundario-claro-sp: rgb(163, 203, 187);
	--oscuro-fondo-sp: rgb(100, 194, 194);
	--oscuro-fondo-claro-sp: #887c77; /*rgb(114, 104, 100); */
	--oscuro-fondo-cabeceras-sp: #a59d9a; 
	--oscuro-fondo-oscuro-sp: rgb(81, 71, 68); 
	--oscuro-fondo-semi-oscuro-sp: rgb(145, 128, 123);
	--oscuro-fondo-secundario-sp: rgb(128, 126, 134);
	--oscuro-fondo-secundario-oscuro-sp: rgb(164, 162, 170);
	--oscuro-fondo-secundario-resaltar-sp: rgb(148, 144, 161);
	--oscuro-fondo-totalizadores-sp: rgb(223, 206, 201);
	--oscuro-botones-sp: coral;
	--oscuro-botones-over-sp: rgb(81, 71, 68);
	--oscuro-botones-secundarios-sp: rgb(100, 194, 194);
	--oscuro-botones-secundarios-hover-sp: rgb(116, 107, 104);
	--oscuro-grupos-sp: rgb(143, 138, 136);
	--oscuro-grupos-hover-sp: rgb(81, 71, 68); 
	--oscuro-filas-tabla-sp: rgb(175, 165, 161); /*rgb(206, 158, 140);*/
	--oscuro-filas-tabla-par-sp: rgb(218, 212, 210); 
	--oscuro-filas-tabla-impar-sp: rgb(81, 71, 68);  
	--oscuro-rojo: rgb(255, 90, 90); 
	--oscuro-verde: rgb(144, 202, 51); 
	--oscuro-fondo-blanco: #0f1e29;
}

.tema-oscuro,
.tema-oscuro pre
{
	color: white;
}

.tema-oscuro .Contenedor,
html, #root
{
	background: rgb(2,0,36);
	background: linear-gradient(60deg, rgba(2,0,36,1) 0%, rgba(18,45,45,1) 25%, rgba(15,30,41,1) 28%, rgba(15,30,41,1) 74%, rgba(18,45,45,1) 79%, rgba(15,30,41,1) 83%, rgba(15,30,41,1) 100%);
}

.tema-oscuro .btn.btn-secundary 
{
    color: var(--oscuro-grupos-sp);
}

.tema-oscuro .acceso-directo
{
	background-color: transparent;
	color: #c1b7b3;
}

.tema-oscuro .acceso-directo:hover
{
	color: var(--fondo-sp);
}

.tema-oscuro nav
{
	background-color: var(--oscuro-fondo-blanco);

	box-shadow: 0px 0px 10px 0px #887c77;
    -o-box-shadow: 0px 0px 10px 0px #887c77;
    -webkit-box-shadow: 0px 0px 10px 0px #887c77;
    -moz-box-shadow: 0px 0px 10px 0px #887c77;
}

.tema-oscuro .fondo-sp
{
	background-color: var(--oscuro-fondo-blanco);	
}

.tema-oscuro .buscador .fondo-blanco,
.tema-oscuro .aviso-inferior .text-white,
.tema-oscuro #Login .fondo-blanco,
.tema-oscuro .emparentados .fondo-blanco,
.tema-oscuro .buscador .fondo-blanco .buscador-seleccionado
{
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro input[type="text"],
.tema-oscuro input[type="date"],
.tema-oscuro input[type="time"],
.tema-oscuro input[type="number"],
.tema-oscuro input[type="password"],
.tema-oscuro select, 
.tema-oscuro textarea, 
.tema-oscuro input
{
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white;
}

.tema-oscuro .input-label
{
	color: var(--oscuro-fondo-sp);
}

.tema-oscuro .tipar-input span
{
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white;	
}

.tema-oscuro .tipar-input span svg
{
	color: white;	
}

.tema-oscuro label.ContainerCheck
{
	color: white;	
}

.tema-oscuro table tr:nth-child(even) 
{ 
	background: var(--filas-tabla-impar-sp); 
	color: white;
}

.tema-oscuro table tr:nth-child(even) input,
.tema-oscuro table tr:nth-child(even) select
{ 
	color: gray !important;
}

.tema-oscuro table tr:nth-child(odd) 
{
    background: var(--oscuro-fondo-oscuro-sp)!important;
}

.tema-oscuro td.sticky-l
{
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white;	
}

.tema-oscuro .gradiente
{
	background-color: var(--oscuro-fondo-blanco) !important;
	background: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .pie-pagina
{
	border-top: gray solid 3px !important;
}

.tema-oscuro .fondo-pie
{
	background-color: var(--oscuro-fondo-blanco) I !important;
}

.tema-oscuro .pie-pagina .borde-blanco
{
	border: 0 !important;
}

.tema-oscuro .Cabecera
{
	background-color: var(--oscuro-fondo-blanco) !important;
	border-bottom: gray solid 1px;
}

.tema-oscuro .dropdown-menu
{
	background-color: var(--oscuro-fondo-blanco) !important;
	border: 1px solid white;
}

.tema-oscuro .dropdown-menu .dropdown-menu-h .fondo-sp
{
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .filtro-consulta.blanco
{
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .Paginas-Consultas, 
.tema-oscuro .Paginas-Consultas button
{
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white !important;
}

.tema-oscuro button.no-select
{
	color: white;
}

.tema-oscuro .btn-secundary
{
	color: white !important;
}

.tema-oscuro .fixed-right.slide-in-right,
.tema-oscuro .fixed-right.slide-out-right
{
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white !important;
}

.tema-oscuro .fixed-right.slide-in-right #achicar,
.tema-oscuro .fixed-right.slide-in-right #agrandar,
.tema-oscuro .fixed-right.slide-in-right #cerrar-slide
{
	background-color: var(--oscuro-fondo-oscuro-sp) !important;	
	color: white !important;
}

.tema-oscuro table .gris
{
	background-color: var(--oscuro-fondo-oscuro-sp) !important;
	color: white !important;
}

.tema-oscuro .logo-principal div span
{
	color: white !important;
}

.tema-oscuro .grilla-consulta.v-0
{
	background-color: transparent !important;
}

.tema-oscuro .grilla-consulta.v-1
{
	background-color: rgb(70, 70, 70) !important;
}

.tema-oscuro .grilla-consulta.v-2
{
	background-color: rgb(100, 100, 100) !important;
}

.tema-oscuro .grilla-consulta.v-3
{
	background-color: rgb(130, 130, 130) !important;
}

.tema-oscuro .grilla-consulta.v-4
{
	background-color: rgb(180, 180, 180) !important;
}

.tema-oscuro .grilla-consulta.v-5
{
	background-color: rgb(220, 220, 220) !important;
}

.tema-oscuro .grilla-consulta.v-6
{
	background-color: rgb(67, 193, 36) !important;
}

.tema-oscuro .grilla-consulta.v--0
{
	background-color: rgb(245, 99, 99) !important;
}

.tema-oscuro .text-center,
.tema-oscuro .text-left,
.tema-oscuro .text-right,
.tema-oscuro .subrayado,
.tema-oscuro h4,
.tema-oscuro h5,
.tema-oscuro h6
{
	color: white !important;
}

.tema-oscuro .hoja-a4,
.tema-oscuro .hoja-a4 .text-center,
.tema-oscuro .hoja-a4 .text-left,
.tema-oscuro .hoja-a4 .text-right
{
	color: black !important;
}

.tema-oscuro .text-gradient,
.tema-oscuro .text-degrade
{
	background: #333 !important;
	background: linear-gradient(45deg, var(--fondo-sp) 20%, var(--botones-sp) 50%, var(--fondo-sp) 70%) !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.tema-oscuro .listado-abm .input-abm:disabled 
{
	color: white;
    background-color: transparent !important;
}

.tema-oscuro .fila-seleccionada input.input-abm,
.tema-oscuro .fila-seleccionada select.input-abm
{
	background-color: transparent !important;
	border: 1px solid white !important;
	color: gray !important;
}

.tema-oscuro .input-control input,
.tema-oscuro .input-control textarea
{
	background-color: transparent !important;
	color: gray !important;
}

.tema-oscuro .Comandera-larga a,
.tema-oscuro .Comandera a
{
	color: gray !important;
}

.tema-oscuro #menu h4,
.tema-oscuro #menu h6 
{
	color: var(--fondo-claro-sp) !important;
}

.tema-oscuro .fondo-aux.fondo-blanco
{
	background-color: var(--oscuro-fondo-blanco) !important;	
}

.tema-oscuro ul li
{
	color: gray;
}

.tema-oscuro .menu-spg.inferior.fondo-sp,
.tema-oscuro ul li.inferior-abierto
{
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white !important;
}

.tema-oscuro .buscador .reg-encontrados .centrados
{
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .buscador .reg-encontrados h3
{
	background-color: transparent !important;
}

.tema-oscuro input.fondo-transparente
{ 
	background-color: transparent !important;
} 

.tema-oscuro .triangulo-blanco 
{
	border-color: transparent transparent var(--oscuro-fondo-blanco) transparent;
}

.tema-oscuro .abm-detalle,
.tema-oscuro .letra-detalle-comprobante,
.tema-oscuro .detalle-comprobante 
{
	background-color: var(--oscuro-fondo-blanco);
}

.tema-oscuro .usuario-gr,
.tema-oscuro .usuario-gr h4,
.tema-oscuro .usuario-gr h6 
{
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .reportes, .reportes-emb 
{
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .server-pegado
{
	border: 2px solid #CCC;
	background-color: var(--oscuro-fondo-blanco);
	color: #FFF;
} 

.tema-oscuro .hoja-a4 h1, .hoja-a4 h2, .hoja-a4 h3, .hoja-a4 h4, .hoja-a4 h1, .hoja-a4 h5, .hoja-a4 h6, .hoja-a4 h7
{
	color: black !important;
}

.tema-oscuro table tr td.fondo-blanco
{
	background: inherit !important;
}

.tema-oscuro .botones-confirmar .btn-secundary 
{
	color: black !important;
}