/* ---------- RESALTAR -------------------------------------------------------*/

@keyframes Resaltar
{
	0%
	{
		translate: -100%;
		transform: scale(1);
	}

	25%
	{
		transform: scale(1.07);
	}

	50%
	{
		translate: -100%;
		transform: scale(1);
	}

	75%
	{
		transform: scale(1.07); 
	}

	100%
	{
		transform: scale(1);
	}
}

@-webkit-keyframes Resaltar
{
	0%
	{
		translate: -100%;
		transform: scale(1);
	}

	25%
	{
		transform: scale(1.07);
	}

	50%
	{
		translate: -100%;
		transform: scale(1);
	}

	75%
	{
		transform: scale(1.07);
	}

	100%
	{
		transform: scale(1);
	}
}

@-o-keyframes Resaltar
{
	0%
	{
		translate: -100%;
		transform: scale(1);
	}

	25%
	{
		transform: scale(1.07);
	}

	50%
	{
		translate: -100%;
		transform: scale(1);
	}

	75%
	{
		transform: scale(1.07);
	}

	100%
	{
		transform: scale(1);
	}
}

@-moz-keyframes Resaltar
{
	0%
	{
		translate: -100%;
		transform: scale(1);
	}

	25%
	{
		transform: scale(1.07);
	}

	50%
	{
		translate: -100%;
		transform: scale(1);
	}

	75%
	{
		transform: scale(1.07);
	}

	100%
	{
		transform: scale(1);
	}
}

/* ---------- RESALTAR SEL -------------------------------------------------------*/

@keyframes ResaltarSel
{
	0%
	{
		transform: scale(1);
	}

	33%
	{
		transform: scale(1.2);
	}

	66%
	{
		transform: scale(1);
	}

	100%
	{
		transform: scale(1.2);
	}
}

@-webkit-keyframes ResaltarSel
{
	0%
	{
		transform: scale(1);
	}

	33%
	{
		transform: scale(1.2);
	}

	66%
	{
		transform: scale(1);
	}

	100%
	{
		transform: scale(1.2);
	}
}

@-moz-keyframes ResaltarSel
{
	0%
	{
		transform: scale(1);
	}

	33%
	{
		transform: scale(1.2);
	}

	66%
	{
		transform: scale(1);
	}

	100%
	{
		transform: scale(1.2);
	}
}

@-ms-keyframes ResaltarSel
{
	0%
	{
		transform: scale(1);
	}

	33%
	{
		transform: scale(1.2);
	}

	66%
	{
		transform: scale(1);
	}

	100%
	{
		transform: scale(1.2);
	}
}

@-o-keyframes ResaltarSel
{
	0%
	{
		transform: scale(1);
	}

	33%
	{
		transform: scale(1.2);
	}

	66%
	{
		transform: scale(1);
	}

	100%
	{
		transform: scale(1.2);
	}
}

/* ---------- APARECER SLIDE -------------------------------------------------------*/

@keyframes AparecerSlide
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

@-webkit-keyframes AparecerSlide
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

@-moz-keyframes AparecerSlide
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

@-ms-keyframes AparecerSlide
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

@-o-keyframes AparecerSlide
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

/* ---------- SLIDE IN RIGHT -------------------------------------------------------*/

@keyframes slide-in-right 
{
	0% { right: -100%; }
    100% { right: 0; }
}

@-webkit-keyframes slide-in-right 
{
	0% { right: -100%; }
    100% { right: 0; }
}

@-moz-keyframes slide-in-right 
{
	0% { right: -100%; }
    100% { right: 0; }
}

@-ms-keyframes slide-in-right 
{
	0% { right: -100%; }
    100% { right: 0; }
}

@-o-keyframes slide-in-right 
{
	0% { right: -100%; }
    100% { right: 0; }
}

/* ---------- SLIDE OUT RIGHT -------------------------------------------------------*/

@keyframes slide-out-right 
{
	0% { right: 0; }
    100% { right: -100%; }
}

@-webkit-keyframes slide-out-right 
{
	0% { right: 0; }
    100% { right: -100%; }
}

@-moz-keyframes slide-out-right 
{
	0% { right: 0; }
    100% { right: -100%; }
}

@-ms-keyframes slide-out-right 
{
	0% { right: 0; }
    100% { right: -100%; }
}

@-o-keyframes slide-out-right 
{
	0% { right: 0; }
    100% { right: -100%; }
}

/* ---------- SLIDE IN LEFT -------------------------------------------------------*/

@keyframes slide-in-left 
{
	0% { left: -100%; }
    100% { left: 0; }
}

@-webkit-keyframes slide-in-left 
{
	0% { left: -100%; }
    100% { left: 0; }
}

@-moz-keyframes slide-in-left 
{
	0% { left: -100%; }
    100% { left: 0; }
}

@-ms-keyframes slide-in-left 
{
	0% { left: -100%; }
    100% { left: 0; }
}

@-o-keyframes slide-in-left 
{
	0% { left: -100%; }
    100% { left: 0; }
}

/* ---------- SLIDE OUT LEFT -------------------------------------------------------*/

@keyframes slide-out-left 
{
	0% { left: 0; }
    100% { left: -100%; }
}

@-webkit-keyframes slide-out-left 
{
	0% { left: 0; }
    100% { left: -100%; }
}

@-moz-keyframes slide-out-left 
{
	0% { left: 0; }
    100% { left: -100%; }
}

@-ms-keyframes slide-out-left 
{
	0% { left: 0; }
    100% { left: -100%; }
}

@-o-keyframes slide-out-left 
{
	0% { left: 0; }
    100% { left: -100%; }
}

/* ---------- SLIDE IN BOTTOM -------------------------------------------------------*/

@keyframes slide-in-bottom 
{
	0% { bottom: -100%; }
    100% { bottom: 0; }
}

@-webkit-keyframes slide-in-bottom 
{
	0% { bottom: -100%; }
    100% { bottom: 0; }
}

@-moz-keyframes slide-in-bottom 
{
	0% { bottom: -100%; }
    100% { bottom: 0; }
}

@-ms-keyframes slide-in-bottom 
{
	0% { bottom: -100%; }
    100% { bottom: 0; }
}

@-o-keyframes slide-in-bottom 
{
	0% { bottom: -100%; }
    100% { bottom: 0; }
}

/* ---------- SLIDE IN TOP -------------------------------------------------------*/

@keyframes slide-in-top 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-webkit-keyframes slide-in-top 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-moz-keyframes slide-in-top 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-ms-keyframes slide-in-top 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-o-keyframes slide-in-top 
{
	0% { top: -100%; }
    100% { top: 0; }
}

/* ---------- SLIDE IN TOP -------------------------------------------------------*/

@keyframes slide-in-top-menu 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-webkit-keyframes slide-in-top-menu 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-moz-keyframes slide-in-top-menu 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-ms-keyframes slide-in-top-menu 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-o-keyframes slide-in-top-menu 
{
	0% { top: -100%; }
    100% { top: 0; }
}

/* ---------- ACHICAR CABECERA -------------------------------------------------------*/

@keyframes AchicarCabeceraComp
{
	0% { transform: scaleY(3); }
    100% { transform: scaleY(1); }
}

@-webkit-keyframes AchicarCabeceraComp
{
	0% { transform: scaleY(3); }
    100% { transform: scaleY(1); }
}

@-moz-keyframes AchicarCabeceraComp
{
	0% { transform: scaleY(3); }
    100% { transform: scaleY(1); }
}

@-ms-keyframes AchicarCabeceraComp
{
	0% { transform: scaleY(3); }
    100% { transform: scaleY(1); }
}

@-o-keyframes AchicarCabeceraComp
{
	0% { transform: scaleY(3); }
    100% { transform: scaleY(1); }
}

/* ---------- GIRAR CHECKS -------------------------------------------------------*/

@-webkit-keyframes Girar-Check
{
	0%
	{
		border-radius: 6px;
	}

	100%
	{
		border-radius: 12px;
	}
}

@-moz-keyframes Girar-Check
{
	0%
	{
		border-radius: 6px;
	}

	100%
	{
		border-radius: 12px;
	}
}

@-o-keyframes Girar-Check
{
	0%
	{
		border-radius: 6px;
	}

	100%
	{
		border-radius: 12px;
	}
}

@keyframes Girar-Check
{
	0%
	{
		border-radius: 6px;
	}

	100%
	{
		border-radius: 12px;
	}
}

/* ---------- CARRUSEL DER a IZQ -------------------------------------------------------*/

@-webkit-keyframes carrusel-izq 
{
	0% 
	{
		opacity: 0.8;
		-webkit-transform: translate3d(500%, 0, 0);
		transform: translate3d(500%, 0, 0);
	}

	100% 
	{
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes carrusel-izq 
{
	0% {
		opacity: 0.8;
		-webkit-transform: translate3d(500%, 0, 0);
		transform: translate3d(500%, 0, 0);
	}
	
	100% 
	{
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
} 

/* ---------- BOUNCE -------------------------------------------------------*/

@-webkit-keyframes bounceIn 
{
	0%, 20%, 40%, 60%, 80%, 100% 
	{
		-webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	
	0% 
	{
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}
	
	20% 
	{
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}
	
	40% 
	{
		-webkit-transform: scale3d(.9, .9, .9);
		transform: scale3d(.9, .9, .9);
	}

	60% 
	{
		opacity: 1;
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% 
	{
		-webkit-transform: scale3d(.97, .97, .97);
		transform: scale3d(.97, .97, .97);
	}
	
	100% 
	{
		opacity: 1;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes bounceIn 
{
	0%, 20%, 40%, 60%, 80%, 100% 
	{
		-webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	
	0% 
	{
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}

	20% 
	{
		-webkit-transform: scale3d(1.3, 1.3, 1.3);
		transform: scale3d(1.3, 1.3, 1.3);
	}

	40% {
		-webkit-transform: scale3d(.9, .9, .9);
		transform: scale3d(.9, .9, .9);
	}
	
	60% {
		opacity: 1;
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		transform: scale3d(1.03, 1.03, 1.03);
	}
	
	80% {
		-webkit-transform: scale3d(.97, .97, .97);
		transform: scale3d(.97, .97, .97);
	}
	
	100% {
		opacity: 1;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
} 