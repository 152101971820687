@media screen and (max-width: 1150px)
{
    .acceso-directo .tarjeta-anuncios 
    {
        padding: 5px !important;
        min-height: 165px;
    }
    
    .acceso-directo h2
    {
        overflow-wrap: break-word !important;
    }

    .landing-pagar
    {
        background: url('/images/pagar.jpg');
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media screen and (width > 741px) and (max-width: 900px)
{
    .acceso-directo .tarjeta-anuncios 
    {
        padding: 5px !important;
        min-height: 165px;
    }
    
    .acceso-directo h2
    {
        overflow-wrap: break-word !important;
    }

    .col-md-6 {
        flex: 0 0 40%;
        max-width: 40%;
        width: 40%;
    }

    .landing-page video
    {
        width: 100%;
    }

    .landing-page video.vertical
    {
        width: 100vw;
        height: auto !important;
    }
} 