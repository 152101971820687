@import url(https://fonts.googleapis.com/css?family=Anton);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Alegreya Sans);
@import url(https://fonts.googleapis.com/css?family=Pattaya);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);
@import url(https://fonts.googleapis.com/css?family=Sacramento);
@import url(https://fonts.googleapis.com/css?family=Bangers);
:root 
{	
	--fondo-secundario-claro-sp: rgb(163, 203, 187);
	--fondo-sp: rgb(100, 194, 194);
	--fondo-claro-sp: #887c77; /*rgb(114, 104, 100); */
	--fondo-cabeceras-sp: #a59d9a; 
	--fondo-oscuro-sp: rgb(81, 71, 68); 
	--fondo-semi-oscuro-sp: rgb(145, 128, 123);
	--fondo-secundario-sp: rgb(128, 126, 134);
	--fondo-secundario-oscuro-sp: rgb(164, 162, 170);
	--fondo-totalizadores-sp: rgb(223, 206, 201);
	--fondo-secundario-resaltar-sp: rgb(148, 144, 161);
	--botones-sp: coral;
	--botones-over-sp: rgb(81, 71, 68);
	--botones-secundarios-sp: rgb(100, 194, 194);
	--botones-secundarios-hover-sp: rgb(116, 107, 104);
	--grupos-sp: rgb(81, 71, 68);
	--grupos-hover-sp: rgb(102, 99, 96);
	--filas-tabla-sp: rgb(175, 165, 161); /*rgb(206, 158, 140);*/
	--filas-tabla-par-sp: rgb(218, 212, 210); 
}

li
{
    list-style: none;
}

a 
{
    color: black;
}

a:hover 
{
    color: rgb(81, 71, 68);
    color: var(--fondo-oscuro-sp);
    text-decoration: none;
}

.clear
{
    clear: both;
}

.btn
{
	display: inline-block;
 	padding: 15px;
 	margin-bottom: 0;
 	font-size: 14px;
 	font-weight: 400;
 	line-height: 1.42857143;
 	text-align: center;
 	white-space: nowrap;
 	vertical-align: middle;
 	touch-action: manipulation;
 	cursor: pointer;
 	-webkit-user-select: none;
 	-ms-user-select: none;
 	user-select: none;
 	background-image: none;
 	border:1px solid transparent;
    border-radius: 4px;
    text-decoration: none; 
    letter-spacing: 3px;
}

div.btn 
{
    padding: 12px 0 12px 0;
}

.btn:focus,.btn:active:focus,.btn.active:focus,.btn.focus,.btn:active.focus,.btn.active.focus
{
	outline:thin dotted;
 	outline:5px auto -webkit-focus-ring-color;
 	outline-offset:-2px
}

.btn:hover,.btn:focus,.btn.focus
{
	color: rgb(81, 71, 68);
	color: var(--fondo-oscuro-sp);
 	text-decoration:none
}

.btn:active,.btn.active
{
	background-image:none;
    outline:0;
 	box-shadow:inset 0 3px 5px rgba(0,0,0,.125)
}

.btn.disabled,.btn[disabled],fieldset[disabled] .btn
{
	pointer-events:none;
 	cursor:not-allowed;
 	filter:alpha(opacity=65);
 	box-shadow:none;
 	opacity:.65
}

.btn-default
{
	background-color: rgb(145, 128, 123);
	background-color: var(--fondo-semi-oscuro-sp);
 	color:#fff;
    border-color:#CCC;
    letter-spacing: 3px;
}

.btn-default:hover,.btn-default:focus,.btn-default.focus,.btn-default:active,.btn-default.active,.open>.dropdown-toggle.btn-default
{
	background-color: #887c77;
	background-color: var(--fondo-claro-sp);
 	color:#e6e6e6;
    border-color:#887c77;
    border-color:var(--fondo-claro-sp);
    transition-duration: 0.5s; 
}

.btn-default:active,.btn-default.active,.open>.dropdown-toggle.btn-default
{
	background-image:none
}

.btn-default.disabled,.btn-default[disabled],fieldset[disabled] .btn-default,.btn-default.disabled:hover,.btn-default[disabled]:hover,fieldset[disabled] .btn-default:hover,.btn-default.disabled:focus,.btn-default[disabled]:focus,fieldset[disabled] .btn-default:focus,.btn-default.disabled.focus,.btn-default[disabled].focus,fieldset[disabled] .btn-default.focus,.btn-default.disabled:active,.btn-default[disabled]:active,fieldset[disabled] .btn-default:active,.btn-default.disabled.active,.btn-default[disabled].active,fieldset[disabled] .btn-default.active
{
	background-color:#fff;
 	border-color:#ccc
}

.btn-default .badge
{
	color:#fff;
 	background-color: rgb(81, 71, 68);
 	background-color: var(--fondo-oscuro-sp)
}

.btn-primary
{
	color: white;
 	background-color: coral;
 	background-color: var(--botones-sp);
    border-color: coral;
    border-color: var(--botones-sp);
}

.btn-secondary
{
    color: white;
    background-color: rgb(100, 194, 194);
    background-color: var(--botones-secundarios-sp);
    border-color: rgb(100, 194, 194);
    border-color: var(--botones-secundarios-sp);
    
	border-radius: 25px !important;
}

.btn-secondary:hover
{
    color: white;
    background-color: rgb(116, 107, 104);
    background-color: var(--botones-secundarios-hover-sp);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary.active,
.open>.dropdown-toggle.btn-secondary
{
	color: white; 
}

.btn-primary-icon
{
    color: rgb(145, 128, 123);
    color: var(--fondo-semi-oscuro-sp);
    cursor: pointer;
    transition-duration: 1s;
}

.btn-primary-icon:hover
{
    color: coral;
    color: var(--botones-sp);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary
{
	color:#fff;
 	background-color: rgb(81, 71, 68);
 	background-color: var(--fondo-oscuro-sp);
    border-color: rgb(81, 71, 68);
    border-color: var(--fondo-oscuro-sp);
    transition-duration: 1s; 
}

.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary
{
    background-image:none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active
{
	background-color: rgb(81, 71, 68);
	background-color: var(--fondo-oscuro-sp);
 	border-color: rgb(100, 194, 194);
 	border-color: var(--fondo-sp)
}

.btn-primary .badge
{
	color: rgb(81, 71, 68);
	color: var(--fondo-oscuro-sp);
 	background-color:#fff
}

.btn-soft
{
    color: gray;
    background-color: rgb(205, 229, 246);
    border-color: rgb(167, 189, 204);
}

.btn-soft:hover,
.btn-soft:focus,
.btn-soft.focus,
.btn-soft:active,
.btn-soft.active,
.open>.dropdown-toggle.btn-soft
{
    background-color: rgb(181, 211, 233);
    border-color: rgb(149, 174, 190);
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12
{
	min-height:1px;
 	/*padding-right:15px;
 	padding-left:15px*/
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12
{
	float:left
}

.col-xs-12
{
	width:100%
}

.col-xs-11
{
	width:91.66666667%
}

.col-xs-10
{
	width:83.33333333%
}

.col-xs-9
{
	width:75%
}

.col-xs-8
{
	width:66.66666667%
}

.col-xs-7
{
	width:58.33333333%
}

.col-xs-6
{
	width:50%
}

.col-xs-5
{
	width:41.66666667%
}

.col-xs-4
{
	width:33.33333333%
}

.col-xs-3
{
	width:25%
}

.col-xs-2
{
	width:16.66666667%
}

.col-xs-1
{
	width:8.33333333%
}

.col-xs-pull-12
{
	right:100%
}

.col-xs-pull-11
{
	right:91.66666667%
}

.col-xs-pull-10
{
	right:83.33333333%
}

.col-xs-pull-9
{
	right:75%
}

.col-xs-pull-8
{
	right:66.66666667%
}

.col-xs-pull-7
{
	right:58.33333333%
}

.col-xs-pull-6
{
	right:50%
}

.col-xs-pull-5
{
	right:41.66666667%
}

.col-xs-pull-4
{
	right:33.33333333%
}

.col-xs-pull-3
{
	right:25%
}

.col-xs-pull-2
{
	right:16.66666667%
}

.col-xs-pull-1
{
	right:8.33333333%
}

.col-xs-pull-0
{
	right:auto
}

.col-xs-push-12
{
	left:100%
}

.col-xs-push-11
{
	left:91.66666667%
}

.col-xs-push-10
{
	left:83.33333333%
}

.col-xs-push-9
{
	left:75%
}

.col-xs-push-8
{
	left:66.66666667%
}

.col-xs-push-7
{
	left:58.33333333%
}

.col-xs-push-6
{
	left:50%
}

.col-xs-push-5
{
	left:41.66666667%
}

.col-xs-push-4
{
	left:33.33333333%
}

.col-xs-push-3
{
	left:25%
}

.col-xs-push-2
{
	left:16.66666667%
}

.col-xs-push-1
{
	left:8.33333333%
}

.col-xs-push-0
{
	left:auto
}

.col-xs-offset-12
{
	margin-left:100%
}

.col-xs-offset-11
{
	margin-left:91.66666667%
}

.col-xs-offset-10
{
	margin-left:83.33333333%
}

.col-xs-offset-9
{
	margin-left:75%
}

.col-xs-offset-8
{
	margin-left:66.66666667%
}

.col-xs-offset-7
{
	margin-left:58.33333333%
}

.col-xs-offset-6
{
	margin-left:50%
}

.col-xs-offset-5
{
	margin-left:41.66666667%
}

.col-xs-offset-4
{
	margin-left:33.33333333%
}

.col-xs-offset-3
{
	margin-left:25%
}

.col-xs-offset-2
{
	margin-left:16.66666667%
}

.col-xs-offset-1
{
	margin-left:8.33333333%
}

.col-xs-offset-0
{
	margin-left:0
}

@media (min-width:768px)
{
    .col-sm-12
    {
        width:100%
    }

    .col-sm-11
    {
        width:91.66666667%
    }

    .col-sm-10
    {
        width:83.33333333%
    }

    .col-sm-9
    {
        width:75%
    }

    .col-sm-8
    {
        width:66.66666667%
    }

    .col-sm-7
    {
        width:58.33333333%
    }

    .col-sm-6
    {
        width:50%
    }

    .col-sm-5
    {
        width:40%
    }

    .col-sm-4
    {
        width:33.33333333%
    }

    .col-sm-3
    {
        width:25%
    }

    .col-sm-2
    {
        width:16.66666667%
    }

    .col-sm-1
    {
        width:8.33333333%
    }

    .col-sm-pull-12
    {
        right:100%
    }

    .col-sm-pull-11
    {
        right:91.66666667%
    }

    .col-sm-pull-10
    {
        right:83.33333333%
    }

    .col-sm-pull-9
    {
        right:75%
    }

    .col-sm-pull-8
    {
        right:66.66666667%
    }

    .col-sm-pull-7
    {
        right:58.33333333%
    }

    .col-sm-pull-6
    {
        right:50%
    }

    .col-sm-pull-5
    {
        right:41.66666667%
    }

    .col-sm-pull-4
    {
        right:33.33333333%
    }

    .col-sm-pull-3
    {
        right:25%
    }

    .col-sm-pull-2
    {
        right:16.66666667%
    }

    .col-sm-pull-1
    {
        right:8.33333333%
    }

    .col-sm-pull-0
    {
        right:auto
    }

    .col-sm-push-12
    {
        left:100%
    }

    .col-sm-push-11
    {
        left:91.66666667%
    }

    .col-sm-push-10
    {
        left:83.33333333%
    }

    .col-sm-push-9
    {
        left:75%
    }

    .col-sm-push-8
    {
        left:66.66666667%
    }

    .col-sm-push-7
    {
        left:58.33333333%
    }

    .col-sm-push-6
    {
        left:50%
    }

    .col-sm-push-5
    {
        left:41.66666667%
    }

    .col-sm-push-4
    {
        left:33.33333333%
    }

    .col-sm-push-3
    {
        left:25%
    }

    .col-sm-push-2
    {
        left:16.66666667%
    }

    .col-sm-push-1
    {
        left:8.33333333%
    }

    .col-sm-push-0
    {
        left:auto
    }

    .col-sm-offset-12
    {
        margin-left:100%
    }

    .col-sm-offset-11
    {
        margin-left:91.66666667%
    }

    .col-sm-offset-10
    {
        margin-left:83.33333333%
    }

    .col-sm-offset-9
    {
        margin-left:75%
    }

    .col-sm-offset-8
    {
        margin-left:66.66666667%
    }

    .col-sm-offset-7
    {
        margin-left:58.33333333%
    }

    .col-sm-offset-6
    {
        margin-left:50%
    }

    .col-sm-offset-5
    {
        margin-left:41.66666667%
    }

    .col-sm-offset-4
    {
        margin-left:33.33333333%
    }

    .col-sm-offset-3
    {
        margin-left:25%
    }

    .col-sm-offset-2
    {
        margin-left:16.66666667%
    }

    .col-sm-offset-1
    {
        margin-left:8.33333333%
    }

    .col-sm-offset-0
    {
        margin-left:0
    }
}

@media (min-width:992px)
{
    .col-md-12
    {
        width:100%
    }

    .col-md-11
    {
        width:91.66666667%
    }

    .col-md-10
    {
        width:83.33333333%
    }

    .col-md-9
    {
        width:75%
    }

    .col-md-8
    {
        width:66.66666667%
    }

    .col-md-7
    {
        width:58.33333333%
    }

    .col-md-6
    {
        width:50%
    }

    .col-md-5
    {
        width:41.66666667%
    }

    .col-md-4
    {
        width:33.33333333%
    }

    .col-md-3
    {
        width:25%
    }

    .col-md-2
    {
        width:16.66666667%
    }

    .col-md-1
    {
        width:8.33333333%
    }

    .col-md-pull-12
    {
        right:100%
    }

    .col-md-pull-11
    {
        right:91.66666667%
    }

    .col-md-pull-10
    {
        right:83.33333333%
    }

    .col-md-pull-9
    {
        right:75%
    }

    .col-md-pull-8
    {
        right:66.66666667%
    }

    .col-md-pull-7
    {
        right:58.33333333%
    }

    .col-md-pull-6
    {
        right:50%
    }

    .col-md-pull-5
    {
        right:41.66666667%
    }

    .col-md-pull-4
    {
        right:33.33333333%
    }

    .col-md-pull-3
    {
        right:25%
    }

    .col-md-pull-2
    {
        right:16.66666667%
    }

    .col-md-pull-1
    {
        right:8.33333333%
    }

    .col-md-pull-0
    {
        right:auto
    }

    .col-md-push-12
    {
        left:100%
    }

    .col-md-push-11
    {
        left:91.66666667%
    }

    .col-md-push-10
    {
        left:83.33333333%
    }

    .col-md-push-9
    {
        left:75%
    }

    .col-md-push-8
    {
        left:66.66666667%
    }

    .col-md-push-7
    {
        left:58.33333333%
    }

    .col-md-push-6
    {
        left:50%
    }

    .col-md-push-5
    {
        left:41.66666667%
    }

    .col-md-push-4
    {
        left:33.33333333%
    }

    .col-md-push-3
    {
        left:25%
    }

    .col-md-push-2
    {
        left:16.66666667%
    }

    .col-md-push-1
    {
        left:8.33333333%
    }

    .col-md-push-0
    {
        left:auto
    }

    .col-md-offset-12
    {
        margin-left:100%
    }

    .col-md-offset-11
    {
        margin-left:91.66666667%
    }

    .col-md-offset-10
    {
        margin-left:83.33333333%
    }

    .col-md-offset-9
    {
        margin-left:75%
    }

    .col-md-offset-8
    {
        margin-left:66.66666667%
    }

    .col-md-offset-7
    {
        margin-left:58.33333333%
    }

    .col-md-offset-6
    {
        margin-left:50%
    }

    .col-md-offset-5
    {
        margin-left:41.66666667%
    }

    .col-md-offset-4
    {
        margin-left:33.33333333%
    }

    .col-md-offset-3
    {
        margin-left:25%
    }

    .col-md-offset-2
    {
        margin-left:16.66666667%
    }

    .col-md-offset-1
    {
        margin-left:8.33333333%
    }

    .col-md-offset-0
    {
        margin-left:0
    }
}

@media (min-width:1200px)
{
    .col-lg-12
    {
        width:100%
    }

    .col-lg-11
    {
        width:91.66666667%
    }

    .col-lg-10
    {
        width:83.33333333%
    }

    .col-lg-9
    {
        width:75%
    }

    .col-lg-8
    {
        width:66.66666667%
    }

    .col-lg-7
    {
        width:58.33333333%
    }

    .col-lg-6
    {
        width:50%
    }

    .col-lg-5
    {
        width:41.66666667%
    }

    .col-lg-4
    {
        width:33.33333333%
    }

    .col-lg-3
    {
        width:25%
    }

    .col-lg-2
    {
        width:16.66666667%
    }

    .col-lg-1
    {
        width:8.33333333%
    }

    .col-lg-pull-12
    {
        right:100%
    }

    .col-lg-pull-11
    {
        right:91.66666667%
    }

    .col-lg-pull-10
    {
        right:83.33333333%
    }

    .col-lg-pull-9
    {
        right:75%
    }

    .col-lg-pull-8
    {
        right:66.66666667%
    }

    .col-lg-pull-7
    {
        right:58.33333333%
    }

    .col-lg-pull-6
    {
        right:50%
    }

    .col-lg-pull-5
    {
        right:41.66666667%
    }

    .col-lg-pull-4
    {
        right:33.33333333%
    }

    .col-lg-pull-3
    {
        right:25%
    }

    .col-lg-pull-2
    {
        right:16.66666667%
    }

    .col-lg-pull-1
    {
        right:8.33333333%
    }

    .col-lg-pull-0
    {
        right:auto
    }

    .col-lg-push-12
    {
        left:100%
    }

    .col-lg-push-11
    {
        left:91.66666667%
    }

    .col-lg-push-10
    {
        left:83.33333333%
    }

    .col-lg-push-9
    {
        left:75%
    }

    .col-lg-push-8
    {
        left:66.66666667%
    }

    .col-lg-push-7
    {
        left:58.33333333%
    }

    .col-lg-push-6
    {
        left:50%
    }

    .col-lg-push-5
    {
        left:41.66666667%
    }

    .col-lg-push-4
    {
        left:33.33333333%
    }

    .col-lg-push-3
    {
        left:25%
    }

    .col-lg-push-2
    {
        left:16.66666667%
    }

    .col-lg-push-1
    {
        left:8.33333333%
    }

    .col-lg-push-0
    {
        left:auto
    }

    .col-lg-offset-12
    {
        margin-left:100%
    }

    .col-lg-offset-11
    {
        margin-left:91.66666667%
    }

    .col-lg-offset-10
    {
        margin-left:83.33333333%
    }

    .col-lg-offset-9
    {
        margin-left:75%
    }

    .col-lg-offset-8
    {
        margin-left:66.66666667%
    }

    .col-lg-offset-7
    {
        margin-left:58.33333333%
    }

    .col-lg-offset-6
    {
        margin-left:50%
    }

    .col-lg-offset-5
    {
        margin-left:41.66666667%
    }

    .col-lg-offset-4
    {
        margin-left:33.33333333%
    }

    .col-lg-offset-3
    {
        margin-left:25%
    }

    .col-lg-offset-2
    {
        margin-left:16.66666667%
    }

    .col-lg-offset-1
    {
        margin-left:8.33333333%
    }

    .col-lg-offset-0
    {
        margin-left:0
    }
}

table
{
	background-color:transparent
}

caption
{
	padding-top:8px;
 	padding-bottom:8px;
 	color:#777;
 	text-align:left
}

th
{
	text-align:left
}

.table
{
	width:100%;
 	max-width:100%;
 	margin-bottom:20px
}

.table>thead>tr>th,.table>tbody>tr>th,.table>tfoot>tr>th,.table>thead>tr>td,.table>tbody>tr>td,.table>tfoot>tr>td
{
	padding:8px;
 	line-height:1.42857143;
 	vertical-align:top;
 	font-size: 15px;
 	border-top:1px solid #ddd
}

.table>thead>tr>th
{
	vertical-align:bottom;
 	border-bottom:2px solid #ddd;
 	color: white;
 	font-size: 15px;
 	background-color: rgb(11, 189, 165);
 	vertical-align: middle;
}

.table>caption+thead>tr:first-child>th,.table>colgroup+thead>tr:first-child>th,.table>thead:first-child>tr:first-child>th,.table>caption+thead>tr:first-child>td,.table>colgroup+thead>tr:first-child>td,.table>thead:first-child>tr:first-child>td
{
	border-top:0
}

.table>tbody+tbody
{
	border-top:2px solid #ddd
}

.table .table
{
	background-color:#fff
}

.table-condensed>thead>tr>th,.table-condensed>tbody>tr>th,.table-condensed>tfoot>tr>th,.table-condensed>thead>tr>td,.table-condensed>tbody>tr>td,.table-condensed>tfoot>tr>td
{
	padding:5px
}

.table-bordered
{
	border:1px solid #ddd
}

.table-bordered>thead>tr>th,.table-bordered>tbody>tr>th,.table-bordered>tfoot>tr>th,.table-bordered>thead>tr>td,.table-bordered>tbody>tr>td,.table-bordered>tfoot>tr>td
{
	border:1px solid #ddd
}

.table-bordered>thead>tr>th,.table-bordered>thead>tr>td
{
	border-bottom-width:2px
}

.table-striped>tbody>tr:nth-child(odd)
{
	background-color:#f9f9f9
}

.table-hover>tbody>tr:hover
{
	background-color: #DDD;
	font-weight: bold;
}

.table-hover>tbody>tr.Anidado:hover
{
	background-color: white;
}

table col[class*=col-]
{
	position:static;
 	display:table-column;
 	float:none
}

table td[class*=col-],table th[class*=col-]
{
	position:static;
 	display:table-cell;
 	float:none
}

.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th
{
	background-color:#f5f5f5;
}

.table-hover>tbody>tr>td.active:hover,.table-hover>tbody>tr>th.active:hover,.table-hover>tbody>tr.active:hover>td,.table-hover>tbody>tr:hover>.active,.table-hover>tbody>tr.active:hover>th
{
	background-color:#e8e8e8
}

.table>thead>tr>td.success,.table>tbody>tr>td.success,.table>tfoot>tr>td.success,.table>thead>tr>th.success,.table>tbody>tr>th.success,.table>tfoot>tr>th.success,.table>thead>tr.success>td,.table>tbody>tr.success>td,.table>tfoot>tr.success>td,.table>thead>tr.success>th,.table>tbody>tr.success>th,.table>tfoot>tr.success>th
{
	background-color:#dff0d8
}

.table-hover>tbody>tr>td.success:hover,.table-hover>tbody>tr>th.success:hover,.table-hover>tbody>tr.success:hover>td,.table-hover>tbody>tr:hover>.success,.table-hover>tbody>tr.success:hover>th
{
	background-color:#d0e9c6
}

.table>thead>tr>td.info,.table>tbody>tr>td.info,.table>tfoot>tr>td.info,.table>thead>tr>th.info,.table>tbody>tr>th.info,.table>tfoot>tr>th.info,.table>thead>tr.info>td,.table>tbody>tr.info>td,.table>tfoot>tr.info>td,.table>thead>tr.info>th,.table>tbody>tr.info>th,.table>tfoot>tr.info>th
{
	background-color:#d9edf7
}

.table-hover>tbody>tr>td.info:hover,.table-hover>tbody>tr>th.info:hover,.table-hover>tbody>tr.info:hover>td,.table-hover>tbody>tr:hover>.info,.table-hover>tbody>tr.info:hover>th
{
	background-color:#c4e3f3
}

.table>thead>tr>td.warning,.table>tbody>tr>td.warning,.table>tfoot>tr>td.warning,.table>thead>tr>th.warning,.table>tbody>tr>th.warning,.table>tfoot>tr>th.warning,.table>thead>tr.warning>td,.table>tbody>tr.warning>td,.table>tfoot>tr.warning>td,.table>thead>tr.warning>th,.table>tbody>tr.warning>th,.table>tfoot>tr.warning>th
{
	background-color:#fcf8e3
}

.table-hover>tbody>tr>td.warning:hover,.table-hover>tbody>tr>th.warning:hover,.table-hover>tbody>tr.warning:hover>td,.table-hover>tbody>tr:hover>.warning,.table-hover>tbody>tr.warning:hover>th
{
	background-color:#faf2cc
}

.table>thead>tr>td.danger,.table>tbody>tr>td.danger,.table>tfoot>tr>td.danger,.table>thead>tr>th.danger,.table>tbody>tr>th.danger,.table>tfoot>tr>th.danger,.table>thead>tr.danger>td,.table>tbody>tr.danger>td,.table>tfoot>tr.danger>td,.table>thead>tr.danger>th,.table>tbody>tr.danger>th,.table>tfoot>tr.danger>th
{
	background-color:#f2dede
}

.table-hover>tbody>tr>td.danger:hover,.table-hover>tbody>tr>th.danger:hover,.table-hover>tbody>tr.danger:hover>td,.table-hover>tbody>tr:hover>.danger,.table-hover>tbody>tr.danger:hover>th
{
	background-color:#ebcccc
}

.table-responsive
{
	min-height:.01%;
 	overflow-x:auto
}

@media screen and (max-width:767px)
{
	.table-responsive
    {
        width:100%;
        margin-bottom:15px;
        overflow-y:hidden;
        -ms-overflow-style:-ms-autohiding-scrollbar;
        border:1px solid #ddd
    }

    .table-responsive>.table
    {
        margin-bottom:0
    }

    .table-responsive>.table>thead>tr>th,.table-responsive>.table>tbody>tr>th,.table-responsive>.table>tfoot>tr>th,.table-responsive>.table>thead>tr>td,.table-responsive>.table>tbody>tr>td,.table-responsive>.table>tfoot>tr>td
    {
        white-space:nowrap
    }

    .table-responsive>.table-bordered
    {
        border:0
    }

    .table-responsive>.table-bordered>thead>tr>th:first-child,.table-responsive>.table-bordered>tbody>tr>th:first-child,.table-responsive>.table-bordered>tfoot>tr>th:first-child,.table-responsive>.table-bordered>thead>tr>td:first-child,.table-responsive>.table-bordered>tbody>tr>td:first-child,.table-responsive>.table-bordered>tfoot>tr>td:first-child
    {
        border-left:0
    }

    .table-responsive>.table-bordered>thead>tr>th:last-child,.table-responsive>.table-bordered>tbody>tr>th:last-child,.table-responsive>.table-bordered>tfoot>tr>th:last-child,.table-responsive>.table-bordered>thead>tr>td:last-child,.table-responsive>.table-bordered>tbody>tr>td:last-child,.table-responsive>.table-bordered>tfoot>tr>td:last-child
    {
        border-right:0
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>th,.table-responsive>.table-bordered>tfoot>tr:last-child>th,.table-responsive>.table-bordered>tbody>tr:last-child>td,.table-responsive>.table-bordered>tfoot>tr:last-child>td
    {
        border-bottom:0
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root 
{	
	--fondo-secundario-claro-sp: rgb(163, 203, 187);
	--fondo-sp: rgb(100, 194, 194);
	--fondo-sp-claro: rgb(177, 212, 212);
	--fondo-claro-sp: #887c77; /*rgb(114, 104, 100); */
	--fondo-cabeceras-sp: #a59d9a; 
	--fondo-oscuro-sp: rgb(81, 71, 68); 
	--fondo-semi-oscuro-sp: rgb(145, 128, 123);
	--fondo-secundario-sp: rgb(128, 126, 134);
	--fondo-secundario-oscuro-sp: rgb(164, 162, 170);
	--fondo-secundario-resaltar-sp: rgb(148, 144, 161);
	--fondo-totalizadores-sp: rgb(92, 70, 62);
	--botones-sp: coral;
	--botones-over-sp: rgb(81, 71, 68);
	--botones-secundarios-sp: rgb(100, 194, 194);
	--botones-secundarios-hover-sp: rgb(116, 107, 104);
	--grupos-sp: rgb(143, 138, 136);
	--grupos-hover-sp: rgb(81, 71, 68); 
	--filas-tabla-sp: rgb(175, 165, 161); /*rgb(206, 158, 140);*/
	--filas-tabla-par-sp: rgb(218, 212, 210); 
	--rojo: rgb(255, 90, 90); 
	--verde: rgb(144, 202, 51); 
	--fondo-blanco: #fff;
	--fondo-cabecera-abm: #FFF;
	--fondo-cabeceras-tablas-sp: #957967;
	--fondo-gris-oscuro-sp: #333;
}

bdi
{
	unicode-bidi: normal;
}

.transition-0-5
{
	transition-duration: 0.5s;
}

.transition-1
{
	transition-duration: 1s;
}

.transition-1-5
{
	transition-duration: 1.5s;
}

.transition-2
{
	transition-duration: 2s;
}

.sobresaltar 
{
	padding: 10px;

	border-radius: 40px;

	background: #fff;

	background: var(--fondo-blanco);
	box-shadow:  20px 20px 60px #d9d9d9,
				 -20px -20px 60px #ffffff;

	height: 200px;
	width: 200px;
	max-height: 200px;
	max-width: 200px;
	min-height: 200px;
	min-width: 200px;

	margin: 30px;

	text-align: center;
	vertical-align: middle; 

	font-size: 18px;
}

.sobresaltar-mediano
{
	border-radius: 30px;
	box-shadow:  15px 15px 50px #d9d9d9,
				 -15px -15px 50px #ffffff;
	
	height: 150px;
	width: 150px;
	max-height: 150px;
	max-width: 150px;
	min-height: 150px;
	min-width: 150px;
	
	margin: 30px;
}

.sobresaltar-chico
{
	border-radius: 20px;
	box-shadow:  10px 10px 40px #d9d9d9,
				 -10px -10px 40px #ffffff;
	
	height: 100px;
	width: 100px;
	max-height: 100px;
	max-width: 100px;
	min-height: 100px;
	min-width: 100px;
	
	margin: 20px;
}

button.no-select,
input.no-select,
select.no-select 
{
	outline: none !important;
}

.v-align-top
{
	vertical-align: top !important;
}

.v-align-middle,
.v-align-center
{
	vertical-align: middle !important;
}

.aviso-inferior
{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	opacity: 1;
	z-index: 100;

	background-color: #887c77;

	background-color: var(--fondo-claro-sp);
	border-top: 3px solid white;
}

.aviso-inferior div
{
	padding: 9px 10px 8px 10px;
}

.aviso-inferior button
{
	position: absolute;
	z-index: 1000;
	top: 10px;
	right: 10px;
}

.aviso-inferior.rojo
{
	background-color: rgb(255, 90, 90);
	background-color: var(--rojo);
}

.aviso-inferior.verde
{
	background-color: rgb(144, 202, 51) !important;
	background-color: var(--verde) !important;
}

.aviso-inferior span
{
	border-radius: 4px;
}

.blur-arriba
{
  position: absolute;
  left: -30%;
  top: -0%;
  z-index: 10;

  background-color:coral;

  background-color:var(--botones-sp);
  
  filter: blur(170px);
  -webkit-filter: blur(170px);
  -moz-filter: blur(170px);

  border-radius: 50%;
  height: 60%;
  width: 60%;
}

.blur-abajo
{
  position: absolute;
  right: -50%;
  bottom: -10%;

  background-color:coral;

  background-color:var(--botones-sp);
  
  filter: blur(170px);
  -webkit-filter: blur(170px);
  -moz-filter: blur(170px);

  border-radius: 50%;
  height: 60%;
  width: 60%;
}

.gradiente
{
	background: linear-gradient(to left bottom, rgb(100, 194, 194), rgb(163, 203, 187) 40%, rgb(100, 194, 194), #887c77 93%);
	background: linear-gradient(to left bottom, var(--fondo-sp), var(--fondo-secundario-claro-sp) 40%, var(--fondo-sp), var(--fondo-claro-sp) 93%);
}

.gradiente-destacar
{
	background: linear-gradient(to left bottom, coral, #887c77, coral);
	background: linear-gradient(to left bottom, var(--botones-sp), var(--fondo-claro-sp), var(--botones-sp));
}

/* Menu Lateral */
nav
{
	position: fixed;
	float: left;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
	color: #fff;
	box-shadow: 0px 0px 10px 0px rgb(55, 55, 55);
	-o-box-shadow: 0px 0px 10px 0px rgb(55, 55, 55);
	-webkit-box-shadow: 0px 0px 10px 0px rgb(55, 55, 55);
	-moz-box-shadow: 0px 0px 10px 0px rgb(55, 55, 55);
	z-index: 10000;
}

nav:hover
{
	overflow-y: scroll;
}

nav .superior
{
	display: none;
}

ul.con-viñetas li
{
	list-style: disc; 
}

ul.con-viñetas li::marker
{
	color: rgb(100, 194, 194);
	color: var(--fondo-sp);
}

nav ul
{
	padding-left: 0;
	margin-top: 0;
}

nav ul li a
{
	text-decoration: none;
}

nav ul li a div,
nav ul li div div
{
	text-align: center;
	width: 40px;
	display: inline-block;
	color: #fff;
	text-decoration: none;
}

nav ul li a,
nav ul li a span,
nav ul li div span
{
	color: #fff;
	text-decoration: none !important;
}

nav ul li 
{
	list-style: none;
	color: white;
	padding: 8px 10px;
	cursor: pointer;
	border-bottom: 1px ridge rgba(255, 255, 255, 0.15);
	white-space: nowrap;
}

nav ul li svg
{
	margin-right: 10px;
	margin-left: 10px;
	width: 20px;
	min-width: 20px;
}

.menu-spg span svg
{
	float: right;
	margin-top: 5px;
}

li.menu-spg span
{
	animation: AparecerSlide 0.3s linear 1;
	-webkit-animation: AparecerSlide 0.3s linear;
	-moz-animation: AparecerSlide 0.3s linear 1;
	-o-animation: AparecerSlide 0.3s linear 1;
}

.inferior
{
	list-style: none;
	color: white;
	border-top: white 3px solid;
	
	background-color: #887c77;
	
	background-color: var(--fondo-claro-sp);
	position: fixed;
	bottom: 0;

	width: 62px;
}

.redes svg 
{
	width: 30px;
	min-width: 30px;
}

.redes img 
{
	height: 25px;
	min-height: 25px;
	width: inherit !important;
	margin-left: 8px;
	margin-right: 8px;
}

.inferior-abierto
{
	border-top: white 3px solid;
	width: 235px;
}

.inferior-abierto div
{
	display: inline-block;
}

nav ul li:hover
{
	background-color: coral;
	background-color: var(--botones-sp);
}

nav ul li.usuario-gr,
nav ul li.usuario-gr:hover
{
	background-color: white;
}

.dropdown-menu>div.no-hover:hover
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp)
}

.dropdown-menu.dropdown-menu-h 
{
	width: inherit !important;
}

.dropdown-menu>div.no-hover div:hover
{
	background-color: coral;
	background-color: var(--botones-sp)
}

nav ul li img
{
	width: 50px;
	height: 50px;
	
	margin-left: -5px;
	margin-right: -3px;
	border-radius: 50%;
}

nav ul li.usuario-gr img
{ 
	border-width: 0;
}

.usuario-gr
{
	width: 100%;
	padding: 5px 0 0 0;
	background-color: #887c77;
	background-color: var(--fondo-claro-sp);
	text-align: center;
}

.usuario-gr img
{
	width: 100px;
	height: 100px;
	overflow: hidden;
	border: 2px rgb(100, 194, 194) solid;
	border: 2px var(--fondo-sp) solid;
	border-radius: 50%;
	background-color: #fff;
	background-color: var(--fondo-blanco);
}

.usuario-gr h4
{
	margin: 0;
	background-color: #fff !important;
	background-color: var(--fondo-blanco) !important;
	color: #887c77;
	color: var(--fondo-claro-sp);
	text-overflow: ellipsis !important;
    overflow-x: hidden;
	max-width: 235px;

	padding: 55px 15px 5px 15px;
	margin-top: -50px;
}

.usuario-gr h6
{
	margin: 0;
	background-color: #fff !important;
	background-color: var(--fondo-blanco) !important;
	color: #887c77;
	color: var(--fondo-claro-sp);
	padding: 5px;
	text-overflow: ellipsis !important;
    overflow-x: hidden;
	max-width: 235px;
}

.logo
{
	height: 150px;
}

nav .logo:hover
{
	background-color: #fff;
	background-color: var(--fondo-blanco);
}

.logo .Nombre, .logo .Sucursal
{
	position: absolute;
	left: -16px;
	font-size: 10px;
	font-weight: bold;
	text-align: left;
	padding-left: 2px;

	top: 90px;
	width: 90px;
	overflow: hidden;
	text-overflow: ellipsis;
	transform: rotate(270deg);
	height: 28px;
}

.logo .Sucursal
{
	left: -2px;
	font-size: 8px;
	font-weight: normal;

	border-top: 1px solid #fff;
}

.dropdown-menu
{
	left: 100%;
	top: inherit !important;
	margin-top: -46px;

	background-color: rgb(100, 194, 194);

	background-color: var(--fondo-sp);
	border-left: solid white 4px;
	border-radius: 0;

	animation: AparecerSlide 0.5s ease 1;
	-o-animation: AparecerSlide 0.5s ease 1;
	-ms-animation: AparecerSlide 0.5s ease 1;
	-moz-animation: AparecerSlide 0.5s ease 1;
	-webkit-animation: AparecerSlide 0.5s ease 1;
}

.dropdown-menu > div
{
	white-space: nowrap!important;
	margin-right: 10px;
}

.dropdown-menu svg,
.dropdown-menu div,
.dropdown-menu span
{
	display: inline-block;
	color: white;
	padding: 2px 0 2px 0;
}

/*.dropdown-menu>li>div>div,
.dropdown-menu>li>div>span
{
	vertical-align: middle;
	display: inline-flex; 
	white-space: nowrap;
}

.dropdown-menu>li>div>span>svg
{ 
	align-self: center!important;
}*/

.dropdown-menu div a
{
	color: white;
	text-decoration: none;
	display: block;
}

.dropdown-menu>div:hover
{
	width: 100%;
	background-color: #887c77;
	background-color: var(--fondo-claro-sp);
}

.dropdown-sub-menu>div:hover
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
}

.dropdown-menu .linea-separadora
{
	width: 100%;
	padding: 0;
	border-bottom: 1px solid white;
}

.dropdown-menu svg
{
	margin-right: 10px;
	margin-left: 10px;
}

.aclaraciones
{
	font-size: 10px;
	color: gray;

	display: block;

	text-transform: none !important;
}

/* Cabecera */
.Cabecera
{
	display: block;
	top: 0;

	position: fixed; 
	width: 100%;
	
	background-color: #887c77;
	
	background-color: var(--fondo-claro-sp);
	height: 40px;
	padding-left: 90px;
	margin-left: -80px;
	margin-right: -10px;
	
	font-size: 18px;
	color: white;
	font-family: Verdana, Geneva, Tahoma, sans-serif;

	z-index: 9999 !important;
}

.Cabecera a
{
	font-size: 18px;
	color: white;
	font-family: Verdana, Geneva, Tahoma, sans-serif;

	font-family: 'Oswald', serif;
    letter-spacing: 1px;
}

.Cabecera a.f-oswald
{
	font-size: 16px;
	font-family: 'Oswald', serif;
    letter-spacing: 1px;
}

.Cabecera a:hover
{
	color: rgb(100, 194, 194);
	color: var(--fondo-sp);
}

/* Pie */

/* Otros */
#Mensajes
{
	border-radius: 3px;
	position: fixed;
	padding: 15px;;
	
	width: 400px;
	bottom: 50px;

	left: calc(50% - 200px);
	-o-left: calc(50% - 200px);
	-ms-left: calc(50% - 200px);
	-moz-left: calc(50% - 200px);
	-webkit-left: calc(50% - 200px);

	text-align: center;
}

#Mensajes span
{
	position: absolute;
	top: 0;
	right: 5px;
	cursor: pointer;
}

.error
{
	background-color: tomato;
	border: solid red 1px;
	color: white;
}

.info
{
	background-color: greenyellow;
	border: solid green 1px;
	color: white;
}

.aviso
{
	background-color: coral;
	border: solid orangered 1px;
	color: white;
}

.Contenedor
{
	background-color: #fff;
	background-color: var(--fondo-blanco);
	padding: 40px 10px 0 80px;
	min-height: 100vh;
	height: 100%;
}

.falta 
{
	color: rgb(100, 194, 194) !important;
	color: var(--fondo-sp) !important;
}

.flotante
{
	position: absolute;

	padding: 20px;
	border-radius: 5px;;

	/*height: 300px;*/
	width: 500px;
	min-width: 500px;

	left: calc(50% - 250px); 
	-o-left: calc(50% - 250px); 
	-ms-left: calc(50% - 250px); 
	-moz-left: calc(50% - 250px); 
	-webkit-left: calc(50% - 250px); 
	
	top: calc(50% - 250px); 
	-o-top: calc(50% - 250px); 
	-ms-top: calc(50% - 250px); 
	-moz-top: calc(50% - 250px); 
	-webkit-top: calc(50% - 250px); 
	
	background-color: rgba(0, 0, 0, 0.35); 
	color: white !important;
	-ms-box-shadow: -3px 3px 5px 0px #FFF;
	-o-box-shadow: -3px 3px 5px 0px #FFF;
	box-shadow: -3px 3px 5px 0px #FFF;
}

.flotante-chico
{
	width: 400px;
	min-width: 400px;

	left: calc(50% - 200px); 
	-o-left: calc(50% - 200px); 
	-ms-left: calc(50% - 200px); 
	-moz-left: calc(50% - 200px); 
	-webkit-left: calc(50% - 200px);

	top: calc(50% - 190px); 
	-o-top: calc(50% - 190px); 
	-ms-top: calc(50% - 190px); 
	-moz-top: calc(50% - 190px); 
	-webkit-top: calc(50% - 190px); 
}

.flotante-grande
{
	width: 700px;

	left: calc(50% - 350px); 
	-o-left: calc(50% - 350px); 
	-ms-left: calc(50% - 350px); 
	-moz-left: calc(50% - 350px); 
	-webkit-left: calc(50% - 350px);
}

.centrado
{
	text-align: center;
}

.mayuscula
{
	text-transform: uppercase;
}

.info-pie
{
	color: #535353;
	font-size: 9px;
}

.Fondo-img
{ 
	background-repeat: no-repeat;
	background-size: cover;
}

.principal .informes
{
	flex: 0 0 100% !important;
	width: 40% !important;
	max-width: 40% !important;
	margin-top: 10px;
}

.logo-principal 
{
	margin-top: 0;
}

.logo-principal img 
{
	margin: 5px;
	display: inline-block;

	/*position: absolute;
	-webkit-left: calc(50% - 105px);
	-moz-left: calc(50% - 105px);
	-ms-left: calc(50% - 105px);
	-o-left: calc(50% - 105px);
	left: calc(50% - 105px);*/
}

.logo-principal h1
{
	display: inline-block;
	margin-top: 8px;

	vertical-align: bottom;

	letter-spacing: 5px;
	color: rgb(100, 194, 194);
	color: var(--fondo-sp);
	font-weight: normal;
	text-transform: uppercase;
	font-size: 12px;
}

.logo-principal h1 span
{
	display: inline-block;
	margin-top: 5px;

	vertical-align: top;

	letter-spacing: 1px;
	color: #555;
	font-weight: normal;
	text-transform: uppercase;
	font-size: 10px;
}

.logo-principal h1 p
{
	display: inline-block;
	margin-top: 5px;

	vertical-align: top;

	letter-spacing: 0;
	color: #888;
	font-weight: normal;
	text-transform: uppercase;
	font-size: 8px;
}

.contenedor-accesos-directos
{
	padding: 0 10px 10px 10px;
	text-align: center;
}

.acceso-directo
{
	background-color: #fff;
	background-color: var(--fondo-blanco);	
	vertical-align: top;
	font-size: 45px;
	color: #887c77;
	color: var(--fondo-claro-sp);
	display: inline-block;
	cursor: pointer;
	text-align: center;
	width: 240px; /* Cambiado por ale, para acomodarse a todos los dispositivos 28/12/2021 */
	min-width: 100px;
	max-width: 400px;
	overflow: hidden;
	padding: 10px;
}

.acceso-directo.fondo-verde,
.acceso-directo.fondo-verde svg,
.acceso-directo.fondo-rojo,
.acceso-directo.fondo-rojo svg
{
	background-color: transparent !important;
}

.acceso-directo.chico
{
	font-size: 35px;
	min-width: 50px;
	max-width: 150px;
	padding: 1px;
}

.acceso-directo.mini
{
	font-size: 25px;
	min-width: 40px;
	max-width: 120px;
	padding: 1px;
	max-height: 130px;
	min-height: 40px;
}

.acceso-directo.mini .tarjeta-anuncios
{
	height: 110px !important;
	min-height: 110px !important;
	max-height: 110px !important;
}

.acceso-directo.mini .my-3
{
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.acceso-directo.chico h2
{
	font-size: 11px;
}

.acceso-directo.chico h3
{
	font-size: 14px;
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	width: 100%;
	bottom: -10px;
	background-color: #fff;
	background-color: var(--fondo-blanco);
}

.acceso-directo:hover
{
	color: rgb(100, 194, 194);
	color: var(--fondo-sp);
	font-weight: bold;
}

.acceso-directo h2
{
	text-transform: uppercase;
	font-size: 0.4em;
	text-align: center;
	overflow-wrap: break-word;
	word-spacing: normal;
	font-weight: normal;
}

.acceso-directo.accesodirecto-info h2
{
	text-transform: none;
	font-size: 0.2em;
}

.acceso-directo.accesodirecto-info img
{
	margin-top: 0 !important;
}

.acceso-directo-resaltado .tarjeta-anuncios
{
	background-color: coral;
	background-color: var(--botones-sp);
}

.acceso-directo.flat .tarjeta-anuncios
{
	border: 1px solid #9b9b9b !important;
	-ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
}

.acceso-directo .brillo:hover
{
	color: white;
}

.acceso-directo .brillo
{
	background: url("/images/brillo.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	position: absolute;
	z-index: 100;

	font-size: 10px;
	color: white;
	vertical-align: middle;
	transform: rotate(-45deg);

	height: 50px;
	width: 50px;
}

.acceso-directo .brillo-naranja
{
	background: url("/images/brillo-naranja.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.fondo-gris-oscuro
{
	background-color: #333;
	background-color: var(--fondo-gris-oscuro-sp);
	color: white;
}

.fondo-verde
{
	background-color: #5fa02d !important;
}

.fondo-amarillo
{
	background-color: rgb(236, 229, 26) !important;
}

.fondo-verde .tarjeta-anuncios
{
	background-color: #91DD58 !important;
}

.fondo-azul .tarjeta-anuncios,
.fondo-azul.tarjeta-anuncios
{
	background-color: #43A9DC !important;
} 

.fondo-rojo
{
	background-color: rgb(255, 90, 90) !important;
	background-color: var(--rojo) !important;
}

.fondo-degrade-gray-t
{
	background: rgb(170,170,170);
	background: linear-gradient(180deg, rgba(170,170,170,1) 10%, rgba(0,212,255,0) 35%, rgba(0,212,255,0) 100%);
}

.fondo-anaranjado,
.fondo-naranja
{
	background-color: rgb(240, 134, 35) !important;
}

.fondo-rojo .tarjeta-anuncios
{
	background-color: tomato !important;
}

.Modal
{
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 10000;
	padding: 10% 15% 10% 15%;
	top: 0;
	left: 0;
}

.Modal-Contenedor
{
	top: 10%;
}

.Modal-Resaltar
{
	animation: Resaltar 0.5s ease 1;
	-o-animation: Resaltar 0.5s ease 1;
	-ms-animation: Resaltar 0.5s ease 1;
	-moz-animation: Resaltar 0.5s ease 1;
	-webkit-animation: Resaltar 0.5s ease 1;
}

.ResaltarSel
{
	animation: ResaltarSel 0.5s ease 1;
	-o-animation: ResaltarSel 0.5s ease 1;
	-ms-animation: ResaltarSel 0.5s ease 1;
	-moz-animation: ResaltarSel 0.5s ease 1;
	-webkit-animation: ResaltarSel 0.5s ease 1;
}

.Modal-Mediano
{
	padding: 100px 25% 0 25%;
}

.Modal-Chico
{
	padding: 100px 50% 0 50%;
}

.Modal-Header
{
	background-color: #887c77;
	background-color: var(--fondo-claro-sp);
	color: white;
	padding: 7px;
	border-radius: 5px 5px 0 0;
}

.Modal-Body
{
	border: 1px solid #887c77;
	border: 1px solid var(--fondo-claro-sp);
	background-color: #fff;
	background-color: var(--fondo-blanco);
	padding: 20px;
	min-height: 200px;
}

.Modal-Footer
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
	padding: 20px 10px 30px 0;
	border: 1px solid #887c77;
	border: 1px solid var(--fondo-claro-sp);
	border-top: 0;
	border-radius: 0 0 5px 5px;
}

.Modal-Footer button
{
	background-color: #887c77;
	background-color: var(--fondo-claro-sp);
	color: white;
	padding: 7px;
	float: right;
	margin-right: 10px;
	min-width: 100px;
}

.Modal-Footer button:hover
{
	border: 1px #887c77 solid;
	border: 1px var(--fondo-claro-sp) solid;
	color: #887c77;
	color: var(--fondo-claro-sp);
	font-weight: bold;
	transition: ease 0.5s;
}

.Modal .Modal-Signo
{
	vertical-align: top; 
	float: left;
	font-size: 120px;
	padding: 0 25px 0 0;
	margin-top: -10px;
}

.Modal .Modal-Mensaje
{
	display: block;
}

/* Objetos */
.ReactFileReader 
{
    content: 'Haga click o arrastre un archivo aquí';
    text-align: center;

    padding-top: 3px; 
    display: block;
    
    border-radius: 8px;
    cursor: pointer;

    width: 100%;
    height: 30px;
    margin-top: 10px;
	
	border: dashed 1px #DDD;
	background-color: transparent;
	color: white;
	overflow: hidden;
}

.ReactFileReader:hover::before 
{
	border-color: #887c77;
	border-color: var(--fondo-claro-sp);
	background-color: gray;
	opacity: 0.5;
}

.ReactFileReader:active::before 
{
	background: -webkit-linear-gradient(top, #f9f9f9, #d3d3d3);
	color: #887c77;
	color: var(--fondo-claro-sp);
}

.tipar-input 
{
	vertical-align: middle;
}

.tipar-input span
{
	border-radius: 3px 0 0 3px;
	border: 1px white solid;
	border-right: 0;
	padding: 3px 6px 2px 6px;
	height: 32px;
	overflow: hidden;
	vertical-align: middle;
	background-color: #fff;
	background-color: var(--fondo-blanco);
	color: #887c77;
	color: var(--fondo-claro-sp);
	display: inline-flex;
}

.tipar-input svg 
{
	vertical-align: middle;
	display: inline-flex;

	margin-top: 4px;
}

.tipar-input input[type="text"],
.tipar-input input[type="date"],
.tipar-input input[type="time"],
.tipar-input input[type="number"],
.tipar-input input[type="password"],
.tipar-input select
{
	vertical-align: middle;
	height: 32px;
	border-left: 0;
	display: inline-flex;
	border: solid 1px white;
	border-radius: 0 3px 3px 0;
	padding-left: 5px;
	padding-right: 5px;
}

.tipar-input select.col-md-11 
{
	width: 91.66666667% !important;
} 

.tipar-input input[type="text"]:focus,
.tipar-input input[type="date"]:focus,
.tipar-input input[type="time"]:focus,
.tipar-input input[type="number"]:focus,
.tipar-input input[type="password"]:focus 
{ 
	outline: none; 
}

.fondo-blanco input[type="text"],
.fondo-blanco input[type="date"],
.fondo-blanco input[type="time"],
.fondo-blanco input[type="number"],
.fondo-blanco input[type="password"]
{
	border: solid 1px #887c77;
	border: solid 1px var(--fondo-claro-sp);
}

input::selection,
textarea::selection
{
	background-color: #887c77;
	background-color: var(--fondo-claro-sp);
	color: white;
}

input.fondo-transparente::selection,
textarea.fondo-transparente::selection
{
	background-color: rgb(196, 196, 196);
	color: #333;
} 

.fondo-blanco
{
	background-color: #fff !important;
	background-color: var(--fondo-blanco) !important;
}

.fondo-gris
{
	background-color: gray !important;
}

.fondo-blanco span
{
	border: 1px #887c77 solid;
	border: 1px var(--fondo-claro-sp) solid;
	border-right: 0;
}

.border-none .fondo-blanco span
{
	border: 0;
}

.fondo-boton
{ 
	background-color: coral !important; 
	background-color: var(--botones-sp) !important;
} 

.fondo-sp
{ 
	background-color: rgb(100, 194, 194) !important; 
	background-color: var(--fondo-sp) !important;
} 

.fondo-cabeceras
{ 
	background-color: #a59d9a !important; 
	background-color: var(--fondo-cabeceras-sp) !important;
} 

.fondo-claro
{ 
	background-color: #887c77 !important; 
	background-color: var(--fondo-claro-sp) !important;
} 

.fondo-pie
{ 
	background-color: #887c77 !important; 
	background-color: var(--fondo-claro-sp) !important;
} 

.fondo-transparente
{ 
	background-color: transparent !important;
} 

.fondo-oscuro
{ 
	background-color: rgb(81, 71, 68) !important; 
	background-color: var(--fondo-oscuro-sp) !important;
} 

.fondo-totalizadores
{ 
	background-color: rgb(92, 70, 62) !important; 
	background-color: var(--fondo-totalizadores-sp) !important;
	color: white;
} 

.filas-tabla-par-sp
{
	background-color: rgb(218, 212, 210);
	background-color: var(--filas-tabla-par-sp);
}

span.derecha
{
	max-width: 60px !important;
    min-width: 60px !important;

	border-left: 0;

	cursor: pointer;

	overflow: visible;
}

.bilateral
{
	border-radius: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
}

.buscador h6
{
	margin: 3px 0 3px 0;
}

.swal-content .buscador h6
{
	border: 0;
	padding-bottom: 0;
}

.buscador input.descrip
{
	width: 100%;
}

.buscador input.codigo
{
	font-size: x-small;
	line-height: 1em;
	text-align: center;
	width: 100%;
	padding-top: 4px;
}

.buscador span
{
	max-width: 30px;
	min-width: 30px;
}

.buscador table tr td,
.buscador table tr:nth-child(odd), 
.buscador table tr:hover,
.buscador table tr td:hover,
.buscador table tr td input:hover
{
	padding: 0;
	background-color: white !important;
	font-weight: normal !important;
	cursor: unset !important;
}

.tema-oscuro .buscador table tr td,
.tema-oscuro .buscador table tr:nth-child(odd), 
.tema-oscuro .buscador table tr:hover,
.tema-oscuro .buscador table tr td:hover,
.tema-oscuro .buscador table tr td input:hover
{
	background-color: transparent !important;
	color: #FFF !important;
}

.buscador input:disabled
{
	background-color: #fff;
	background-color: var(--fondo-blanco);
	color: gray;
}

.buscador .buscador-seleccionado
{
	color: rgb(100, 194, 194);
	color: var(--fondo-sp);
	border-color: rgb(100, 194, 194);
	border-color: var(--fondo-sp);
	height: 32px; 
}

.buscador .buscador-no-seleccionado
{
	height: 32px;
}

.buscador .icono-productos
{
	width: 40px;
}

.buscador .icono-buscador
{
	width: 20px;
}

.buscador .codigo-buscador
{
	width: 50px;
}

.buscador button.menu svg
{
	vertical-align: middle !important;
	display: inline-flex !important;

	margin-top: -5px !important;
	margin-left: -5px !important;
	margin-right: 5px !important;

	color: gray;
}

.buscador .dropdown-menu
{
	margin-top: -56px;
	margin-left: 25px;
	width: inherit;
}

.buscador .buscador-seleccionado button.menu svg
{
	color: rgb(100, 194, 194);
	color: var(--fondo-sp);
}

.buscador div.fondo-blanco table tr
{
	background-color: transparent !important;
}

input.buscador-seleccionado, 
input:disabled.buscador-seleccionado 
{
	border-color: rgb(100, 194, 194) !important;
	border-color: var(--fondo-sp) !important;
	background-color: #fff !important;
	background-color: var(--fondo-blanco) !important;
	color: rgb(145, 128, 123) !important;
	color: var(--fondo-semi-oscuro-sp) !important;
} 

.buscador .reg-encontrados
{
	position: fixed;
	left: 0;
	top: 0;
	max-width: none;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.buscador .reg-encontrados .centrados
{
	position: fixed;
	width: 75%;
	height: 75%;
	-webkit-left: calc(15%);
	-moz-left: calc(15%);
	-ms-left: calc(15%);
	-o-left: calc(15%);
	left: calc(15%);
	-webkit-top: calc(15%);
	-moz-top: calc(15%);
	-ms-top: calc(15%);
	-o-top: calc(15%);
	top: calc(15%);

	background-color: #fff;

	background-color: var(--fondo-blanco);
	text-align: center;
	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.buscador .reg-encontrados .centrados.grande
{
	position: fixed;

	width: calc(95% - 25px);
	height: 95%;
	
	-webkit-left: calc(2.5% + 40px);
	-moz-left: calc(2.5% + 40px);
	-ms-left: calc(2.5% + 40px);
	-o-left: calc(2.5% + 40px);
	left: calc(2.5% + 40px);
	-webkit-top: calc(2.5%);
	-moz-top: calc(2.5%);
	-ms-top: calc(2.5%);
	-o-top: calc(2.5%);
	top: calc(2.5%);
}

.buscador .reg-encontrados table
{
	width: 100%;
}

table td,
table th
{
	padding: 5px 10px;
	vertical-align: middle;
}

.va-t
{
	vertical-align: top;
}

table tr:nth-child(odd) 
{ 
	background: rgb(218, 212, 210); 
	background: var(--filas-tabla-par-sp); 
}

table tr:hover,
table tr td.sticky-l:hover,
table tr td input:disabled:hover,
table tr td select:disabled:hover,
table tr:nth-child(odd):hover 
{
	background-color: rgb(177, 212, 212) !important;
	background-color: var(--fondo-sp-claro) !important;
	border-color: rgb(100, 194, 194);
	border-color: var(--fondo-sp);
	color: #333 !important;
	/*font-weight: bolder;*/
	cursor: pointer;
}

table tr td:hover svg
{
	color: inherit !important;
}

table tr .text-resaltado:hover
{
	color: #535353 !important;
}

table th
{
	background-color: #957967;
	background-color: var(--fondo-cabeceras-tablas-sp);
	color: white;
}

table td.fila-seleccionada
{
	background-color: rgb(163, 203, 187);
	background-color: var(--fondo-secundario-claro-sp);
	color: #333;
	font-weight: bold;
}

.buscador .reg-encontrados #cerrar
{
	z-index: 10;
	float: right;
	position: absolute;
	top: -2px;
	right: 5px;
	background-color: transparent;
	border: 0;
	color: tomato;
}

.bloque-controles
{
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
}

.ContainerCheck
{
	padding-left: 40px;
	cursor: pointer;
}


.ContainerCheck input
{
	display: none;
}

/* Crea un checkbox personalizado */
.checkmark 
{
	position: absolute;
	top: 3px;
	left: 0;
	margin-top: -2px;
	height: 25px;
	width: 25px;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.45);
	border: solid 3px #887c77;
	border: solid 3px var(--fondo-claro-sp);
	border-radius: 6px;
}

/* colorea al pasar con el mouse */
.ContainerCheck:hover input ~ .checkmark 
{
	opacity: 0.8 !important;
	border-color: solid 2px #555 !important;
	border-radius: 6px;

	margin-top: -2px;
}

/* chequeado */
.ContainerCheck input:checked ~ .checkmark 
{
    background-color: rgb(100, 194, 194);
    background-color: var(--fondo-sp);
    border-color: rgb(100, 194, 194);
    border-color: var(--fondo-sp);
	margin-top: -2px;
	border-radius: 12px;

	-webkit-animation: Girar-Check 0.15s linear;
	animation: Girar-Check 0.15s linear;
}

/* marca chequeada */
.checkmark:after 
{
	content: "";
	position: absolute;
	display: none;
}

/* muestra la marca chequeada */
.ContainerCheck input:checked ~ .checkmark:after 
{
	display: block;
}

.ContainerCheck input:disabled ~ .checkmark
{
	background-color: #888;
	border-color: #888;
}

/* estilo indicador de la marca chequeada */
.ContainerCheck .checkmark:after 
{
	left: 4px;
	margin-top: 2px;
	margin-left: 2px;
	width: 7px;
	height: 12px;
	border: solid white;
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
}

.chk-Inverso
{
	background-color: #fff !important;
	background-color: var(--fondo-blanco) !important;
	border-color: white !important;
}

.ContainerCheck .chk-Inverso:after 
{
	left: 4px !important;
	margin-top: 1px !important;
	margin-left: 1px !important;
	width: 8px !important;
	height: 12px !important;
	border: solid #887c77 !important;
	border: solid var(--fondo-claro-sp) !important;
	border-width: 0 3px 3px 0 !important;
	transform: rotate(45deg) !important;
}

.chk-Grilla
{
	padding-top: 0;
	margin-top: 0;
}

.grilla-consulta
{
	height: 12px;
	padding-left: 6px;
	padding-right: 6px;

	margin: 3px 0 0 3px;
	border-radius: 2px;
}

.grilla-consulta.v--0
{
	background-color: rgb(245, 99, 99) !important;
}

.grilla-consulta.v-0
{
	background-color: rgb(255, 255, 255);
}

.grilla-consulta.v-1
{
	background-color: rgb(240, 245, 245) !important;
}

.grilla-consulta.v-2
{
	background-color: rgb(168, 194, 194) !important;
}

.grilla-consulta.v-3
{
	background-color: rgb(130, 154, 154) !important;
}

.grilla-consulta.v-4
{
	background-color: rgb(82, 140, 140) !important;
}

.grilla-consulta.v-5
{
	background-color: rgb(23, 85, 85) !important;
}

.grilla-consulta.v-6
{
	background-color: rgb(67, 193, 36) !important;
}

/*.datos-abm-detalle .checkmark,
.datos-abm-detalle .checkmark:hover
{
	background-color: var(--fondo-oscuro-sp) !important;
	border-color: var(--fondo-oscuro-sp) !important;
}*/

/* HTML */

h6
{
	margin: 10px 0;
}

button
{
	border: 0;
	background-color: transparent;
}

.detalle-fp
{
	width: 100%;
	margin-top: 20px;
}

.detalle-fp td,
.detalle-fp th
{
	padding: 5px;
}

.detalle-fp th
{
	color: white;
	background-color: #887c77;
	background-color: var(--fondo-claro-sp);
}

.forma-pago-datos
{
	background-color: rgb(218, 212, 210);
	background-color: var(--filas-tabla-par-sp);
	display: none;
	border-radius: 5px;
	padding: 10px;
}

.forma-pago-datos li
{
	list-style: none;
}

.forma-pago-datos button
{
	float: right;
	z-index: 100;
}

.sinpadding [class*="col-"] 
{
    padding: 0;
}

.sinpadding h6
{
	margin-top: 0;
}

.sinicono input
{
	width: 100% !important;	
}

.conpadding 
{
    padding: 15px !important;
}

.detalle-fp-lista 
{
	display: inline-block;
	/*padding-right: 15px !important;*/
}

.input-control 
{
	display: inline-block;
	padding-right: 10px;
	margin-top: 8px;
	margin-bottom: 8px;
}

.input-control.text-right input
{
	text-align: right !important;
}

.invisible
{
	display: none !important;
}
   
.md-invisible,
.invisible-md
{
	visibility: hidden !important;
}

.input-control h6
{
	margin-bottom: 3px;
}

.swal-content .input-control h6
{
	border: 0;
	padding-bottom: 0;
}

input:-internal-autofill-selected
{
	background-color: #fff !important;
	background-color: var(--fondo-blanco) !important; 
	box-shadow: 0 0 0px 1000px white inset !important; 
}

.input-control input[type="text"],
.input-control input[type="number"],
.input-control input[type="date"],
.input-control input[type="mail"],
.input-control input[type="password"]
{
	width: calc(100% - 50px);
	-o-width: calc(100% - 50px);
	-ms-width: calc(100% - 50px);
	-moz-width: calc(100% - 50px);
	-webkit-width: calc(100% - 50px);

	outline: none;

	height: 31px;
}

.input-control textarea
{
	height: inherit;
	width: 100%;
}

.col-lg-12 select,
.col-md-12 select,
.col-xs-12 select
{
	width: 100%;
}

.col-lg-11 select,
.col-md-11 select,
.col-xs-11 select
{
	width: 100%;
}

.col-lg-10 select,
.col-md-10 select,
.col-xs-10 select
{
	width: 100%;
}

.col-lg-9 select,
.col-md-9 select,
.col-xs-9 select
{
	width: 100%;
}

.col-lg-8 select,
.col-md-8 select,
.col-xs-8 select
{
	width: 100%;
}

.col-lg-7 select, 
.col-md-7 select, 
.col-xs-7 select
{
	width: 100%;
}

.col-lg-6 select, 
.col-md-6 select, 
.col-xs-6 select
{
	width: 100%;
}

.col-lg-5 select,
.col-md-5 select,
.col-xs-5 select
{
	width: 100%;
}

.col-lg-4 select,
.col-md-4 select,
.col-xs-4 select
{
	width: 100%;
}

.col-lg-3 select,
.col-md-3 select,
.col-xs-3 select
{
	width: 100%;
}

.col-lg-2 select,
.col-md-2 select,
.col-xs-2 select
{
	width: 100%;
}

.col-lg-1 select,
.col-md-1 select,
.col-xs-1 select
{
	width: 100%;
}

.input-control select
{
	height: 30px;
	margin-top: 2px;
}

.input-control textarea
{
	vertical-align: top;
}

.icono-control svg
{
	z-index: 1000;
	margin-right: 10px;
}

.type-light
{
	/*border: #AAA 1px solid !important;
	opacity: 0.8 !important;
	color: #333 !important;*/
	
	display: none !important;
}

.type-success 
{
	background-color: rgb(100, 194, 194) !important;
	background-color: var(--fondo-sp) !important;
	border-width: 0 !important;
	border-left: solid #887c77 10px !important;
	border-left: solid var(--fondo-claro-sp) 10px !important;
	border-right: solid #887c77 10px !important;
	border-right: solid var(--fondo-claro-sp) 10px !important;

	max-width: 400px;
}

.__react_component_tooltip::after 
{
	display: none !important;
}

.__react_component_tooltip.show
{
	opacity: 0.75 !important;
	transition-delay: 1s;
	white-space: pre-wrap;
}

.float-right
{
	float: right;
}

.no-float,
.float-none
{
	float: none !important;
}

.rojo
{
	color: tomato;
}

.nota
{
	font-size: small;
	padding-top: 15px;
	padding-bottom: 15px;
	color: #887c77;
	color: var(--fondo-claro-sp);
}

.formas-pago-ctacte
{
	border: 1px solid rgb(145, 128, 123);
	border: 1px solid var(--fondo-semi-oscuro-sp);
	border-radius: 5px;

	width: 100%;
	padding-left: 0;
}

.formas-pago-ctacte li
{
	list-style: none;
	border-bottom: rgb(145, 128, 123) solid 1px;
	border-bottom: var(--fondo-semi-oscuro-sp) solid 1px;
	cursor: pointer;
	padding: 5px 5px 5px 15px;
}

.formas-pago-ctacte li:hover
{
	background-color: rgb(81, 71, 68);
	background-color: var(--fondo-oscuro-sp);
	color: white;
}

.formas-pago-ctacte li.seleccionado
{
	list-style: none;
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
	color: white;
}

.formas-pago-ctacte li:last-child 
{
	border-bottom: 0;
}

.buscador-abm
{
	padding-left: 0;
}

.paginas-abm h6
{
	/*
	padding-bottom: 10px;
	border-bottom: var(--fondo-secundario-sp) 1pt dashed;
	text-align: right;
	*/ 
}

.paginas-abm span
{
	color: white;
	padding: 3px 6px 3px 6px;
	min-width: 30px;
	margin-right: 2px;
}

.paginas-abm span.normal
{
	cursor: pointer;
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
}

.paginas-abm span.normal:hover
{
	background-color: rgb(163, 203, 187);
	background-color: var(--fondo-secundario-claro-sp);
}

.paginas-abm span.seleccionado
{
	background-color: rgb(145, 128, 123);
	background-color: var(--fondo-semi-oscuro-sp);
}

.listado-abm .input-abm:disabled
{
	border: 0;
	background-color: transparent;
}

.listado-abm .input-abm
{
	border: 0;
	background-color: #fff;
	background-color: var(--fondo-blanco);
}

.input-label
{
	margin-bottom: 1px;
	margin-top: 5px;
}

.agrandar-1-1:hover,
.agrandar-1-5:active,
.agrandar-2:active,
.agrandar-3:active,
.agrandar-4:active,
.agrandar-5:active,
.agrandar-6:active,
.agrandar-7:active,
.agrandar-8:active,
.agrandar-9:active,
.agrandar-10:active
{
	transition-duration: 0.75s;
}

.agrandar-1-1:hover
{
	transform: scale(1.03);
	z-index: 1000;
}

.agrandar-1-5:active
{
	transform: scale(1.5);
	z-index: 1000;
}

.agrandar-2:active
{
	transform: scale(2);
	z-index: 1000;
}

.agrandar-3:active
{
	transform: scale(3);
	z-index: 1000;
}

.agrandar-4:active
{
	transform: scale(4);
	z-index: 1000;
}

.agrandar-5:active
{
	transform: scale(5);
	z-index: 1000;
}

.agrandar-6:active
{
	transform: scale(6);
	z-index: 1000;
}

.agrandar-7:active
{
	transform: scale(7);
	z-index: 1000;
}

.agrandar-8:active
{
	transform: scale(8);
	z-index: 1000;
}

.agrandar-9:active
{
	transform: scale(9);
	z-index: 1000;
}

.agrandar-10:active
{
	transform: scale(10);
	z-index: 1000;
}

.Comandera
{
	width: 117px;
}

.Comandera-larga
{
	width: 155px;
}

.Comandera button,
.Comandera-larga button
{
	color: #887c77;
	color: var(--fondo-claro-sp);
	margin-right: 10px;
}

.cargando-bloque
{
	height: 300px;
	margin-top: 0;

	border-radius: 10px;

	background-color: #a59d9a;
	color:  white;

	text-align: center;
	vertical-align: middle;

	overflow: hidden;
}

.cargando-bloque .texto
{
	left: 0;
	width: 100%;
	position: absolute;
    margin-top: 30px;
	background-color: transparent;
	display: inline;

	z-index: 1000;

	/*-webkit-animation-name: bounceIn;
	animation-name: bounceIn;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;*/
}

.bounce-in
{
	-webkit-animation-name: bounceIn;
	animation-name: bounceIn;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.bounce-in.bounce-infinite
{
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.bounce-in.bounce-1
{
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

.bounce-in.bounce-2
{
	-webkit-animation-iteration-count: 2;
	animation-iteration-count: 2;
}

.bounce-in.bounce-3
{
	-webkit-animation-iteration-count: 3;
	animation-iteration-count: 3;
}

.cargando-bloque .reflejo
{
	height: 300px;
	width: 300px;
	margin-left: -100%;

	background: linear-gradient(to left , transparent, rgb(163, 203, 187), transparent);

	background: linear-gradient(to left , transparent, var(--fondo-secundario-claro-sp), transparent);
	
	-webkit-animation-name: carrusel-izq;
	animation-name: carrusel-izq;
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;	
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.comprobante-cabecera
{
	color: gray;
}

.comprobante-cabecera-aux
{
	margin-top: 200px;
}

.comprobante-cabecera-fixed
{
	position: fixed;
	top: 50px;
	max-height: 80px;

	z-index: 1000;
	
	-webkit-animation: AchicarCabeceraComp 0.1s linear;
	animation: AchicarCabeceraComp 0.1s linear;
}

.comprobante-cabecera-fixed .datos-detalle-comprobante
{
	padding-top: 0;
}

.comprobante-cabecera-fixed .datos-detalle-comprobante h3
{
	margin-top: 20px;
}

.comprobante-cabecera-fixed .letra-detalle-comprobante
{
	font-size: 50px;
    padding-top: 0;

	border-right: #888 1px solid;
	-ms-box-shadow: 3px 0 10px 0px rgba(119,119,119,1);
	-o-box-shadow: 3px 0 10px 0px rgba(119,119,119,1);
	box-shadow: 3px 0 10px 0px rgba(119,119,119,1);
}

.filtro-consulta,
.comprobante-cabecera,
.abm-detalle 
{
	margin-top: 10px;
	margin-bottom: 30px;
	overflow: hidden;
	/*border-radius: 7px; 

	-webkit-box-shadow: -3px 3px 5px 0px rgba(119,119,119,1);
	-moz-box-shadow: -3px 3px 5px 0px rgba(119,119,119,1);
	-ms-box-shadow: -3px 3px 5px 0px rgba(119,119,119,1);
	-o-box-shadow: -3px 3px 5px 0px rgba(119,119,119,1);
	box-shadow: -3px 3px 5px 0px rgba(119,119,119,1);*/ 

	border-bottom: dashed 1px #A3A3A3;

	/*background: linear-gradient(to left bottom, var(--fondo-sp), var(--fondo-sp), var(--fondo-secundario-claro-sp), var(--fondo-secundario-claro-sp), var(--fondo-sp), var(--fondo-claro-sp));*/

	background-color: #FFF;

	background-color: var(--fondo-cabecera-abm);
}

.abm-detalle h6,
.abm-detalle input[type="text"],
.abm-detalle textarea
{
	color: gray;
}

.filtro-consulta label,
.abm-detalle label
{
	color: black;
	margin-left: 10px;
	padding-left: 5px;
	vertical-align: top; 
	cursor: pointer;
	display: inline;
	display: initial;
}

.input-control.switch input[type="checkbox"]
{
	height: 0;
	width: 0;
	visibility: hidden;
}

.input-control.switch input:checked + label:after
{
	left: calc(100% - 2px);
	transform: translate(-100%);
	background-color: coral;
	background-color: var(--botones-sp);
	border: solid 1px white;
}

.input-control.switch input:checked + label
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
}

.input-control.switch input:disabled + label
{
	background-color: #d3d3d3c2;
}

.input-control.switch label:active
{
	width: 16px;
}

.input-control.switch label 
{
	vertical-align: middle;
	width: 40px;
	height: 16px;

	background-color: #fff;

	background-color: var(--fondo-blanco);
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	transition: 0.5s;
	box-shadow: 0 0 5px gray;
}

.input-control.switch label::after
{
	content: "";
	width: 12px;
	height: 12px;

	background-color: gray;
	border-radius: 6px;
	position: absolute;
	cursor: pointer;
	top: 2px;
	left: 1px;
	transition: 0.3s;
}

.input-control.switch h6
{
	margin-top: -6px !important;
	margin-left: 10px !important;
	vertical-align: middle;

	width: 80% !important;
}

.filtro-consulta input[type="checkbox"],
.abm-detalle input[type="checkbox"]
{
	vertical-align: middle;
}

.abm-detalle input[type="checkbox"]
{
	cursor: pointer;
}

.abm-detalle .t1 input
{
	height: 42px;
}

.abm-detalle .t1 h6,
.abm-detalle .t1 input,
.abm-detalle .t1 textarea,
.abm-detalle .t1 svg 
{
	font-size: xx-large;
}

.abm-detalle .t2 h6,
.abm-detalle .t2 input,
.abm-detalle .t2 textarea,
.abm-detalle .t2 svg  
{
	font-size: x-large;
}

.abm-detalle .t3 h6,
.abm-detalle .t3 input,
.abm-detalle .t3 textarea,
.abm-detalle .t3 svg  
{
	font-size: large;
}

.abm-detalle .t4 h6,
.abm-detalle .t4 input,
.abm-detalle .t4 textarea,
.abm-detalle .t4 svg  
{
	font-size: 12px;
}

.abm-detalle .t5 h6,
.abm-detalle .t5 input,
.abm-detalle .t5 textarea,
.abm-detalle .t5 svg  
{
	font-size: 10px;
}

.abm-detalle .input-control
{
	vertical-align: bottom;
	width: 100%;
	margin-top: 10px;
}

.datos-abm-detalle
{
	padding: 5px 20px;
}

.datos-abm-detalle input,
.datos-abm-detalle textarea 
{
	background-color: transparent;
	border: 0;
}

.datos-abm-detalle svg
{
	color: gray;
}

.datos-abm-detalle input.edicion
{
	background-color: inherit;
	border: inherit;
}

.abm-detalle div.foto-abm-detalle
{
	background-color: #fff;
	background-color: var(--fondo-blanco);
	min-height: 411px;

	border-right: #888 1px solid;
	-ms-box-shadow: 3px 0 10px 0px rgba(119,119,119,1);
	-o-box-shadow: 3px 0 10px 0px rgba(119,119,119,1);
	box-shadow: 3px 0 10px 0px rgba(119,119,119,1);
}

.abm-detalle img.foto-abm-detalle
{
	padding-left: 0;
}

.foto-abm-detalle .foto-oculta
{
	display: none;
}

.abm-detalle .foto-selector
{
	background-color: #535353;
	border-radius: 50%;
	border: 1px solid #d3d3d3;
	width: 10px;
	height: 10px;
	position: absolute;
	bottom: 20px;
	cursor: pointer;
}

.abm-detalle #foto1
{
	left: calc(50% - 40px);
	-o-left: calc(50% - 40px);
	-ms-left: calc(50% - 40px);
	-moz-left: calc(50% - 40px);
	-webkit-left: calc(50% - 40px);
}

.abm-detalle #foto2
{
	left: calc(50% - 20px);
	-o-left: calc(50% - 20px);
	-ms-left: calc(50% - 20px);
	-moz-left: calc(50% - 20px);
	-webkit-left: calc(50% - 20px);
}

.abm-detalle #foto3
{
	left: 50%;
}

.abm-detalle #foto4
{
	left: calc(50% + 20px);
	-o-left: calc(50% + 20px);
	-ms-left: calc(50% + 20px);
	-moz-left: calc(50% + 20px);
	-webkit-left: calc(50% + 20px);
}

.abm-detalle .foto-selector.seleccionado
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
	border: 2px solid rgb(100, 194, 194);
	border: 2px solid var(--fondo-sp);
}

select 
{
	cursor: pointer;
	/*background-image: url("/images/flecha.jpg"); 
	background-repeat: no-repeat;
	background-position: right center;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;*/
}

/* Evita que se muestre la flecha por defecto en versiones de IE*/
/*select::-ms-expand 
{
	display: none; 
}*/

/* DIVS COLLAPSE */
.data-group 
{
	background-color: rgb(143, 138, 136);
	background-color: var(--grupos-sp);
	color: white;
	width: 100%;
	padding: 10px;
	display: inline-block;
	border-radius: 5px;
	text-align: center;
	letter-spacing: 2px;
}

.data-group:hover
{
	color: white;
	background-color: rgb(81, 71, 68);
	background-color: var(--grupos-hover-sp);
	font-weight: bold;
	transition-duration: 0.5s;
	letter-spacing: 4px;
}

.tarjeta
{
	margin-left: 2px;
	padding-right: 10px;
	padding-left: 0;
	padding-bottom: 20px;
}

.collapse .card-body
{
	margin-top: 2px;
	border-radius:  5px;
	margin-left: -16px;
	margin-right: -16px;
	padding: 1.2rem;
}

.tarjeta-anuncios
{
	margin: 0.25em;
	border-radius: 1px;
	padding: 25px 20px 20px 30px !important;

	vertical-align: middle;
	-ms-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
	-o-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
	box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
}

.tarjeta-anuncios:hover 
{
	transition-duration: 0.5s;
	/*transform: scale(1.008);*/
	-ms-box-shadow: -3px 3px 12px 2px rgb(100, 194, 194);
	-ms-box-shadow: -3px 3px 12px 2px var(--fondo-sp);
	-o-box-shadow: -3px 3px 12px 2px rgb(100, 194, 194);
	-o-box-shadow: -3px 3px 12px 2px var(--fondo-sp);
	box-shadow: -3px 3px 12px 2px rgb(100, 194, 194);
	box-shadow: -3px 3px 12px 2px var(--fondo-sp);
}

.tarjeta-anuncios .reg-encontrados:hover 
{
	transition-duration: 10s;
	transform: scale(1);
}

.acceso-directo .tarjeta-anuncios 
{
	padding: 25px !important;
	min-height: 165px;
}

.acceso-directo.chico
{
	max-width: 175px;
}

.acceso-directo.chico .tarjeta-anuncios
{
	max-width: 150px;
	padding: 15px !important;
	min-height: 120px !important;
}

.acceso-directo.grande
{
	min-width: 250px;
	max-width: 250px;
	min-height: 180px;
	max-height: 180px;

	font-size: 38px;
}

.acceso-directo.grande .tarjeta-anuncios
{
	max-width: 205px;
	padding: 15px !important;
	min-height: 150px !important;
}

.botones-confirmar 
{
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 60px;
	padding: 5px 50	px 5px 15px;

	z-index: 1000;

	background-color: rgb(218, 212, 210);

	background-color: var(--filas-tabla-par-sp);
}

.boton-recortar
{
	position: absolute;
	right: 10px;
	top: 44px;
	width: 30px;
	height: 30px;

	color: #DDD;
	background-color: rgba(30, 30, 30, 0.4);
	border-radius: 2px;
}

.comandos-recortar
{
	position: absolute;
	margin-right: 62px;
	padding-left: 5px;

	top: -5px;
	width: 375px;
	height: 115px;

	color: #DDD;
	background-color: rgba(30, 30, 30, 0.4);
	border-radius: 2px;
}

.comandos-recortar button 
{
	margin: 2px 8px;
}

.cuadro-recortar
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	border: #5fa02d 2px dashed;
}

.boton-camara
{
	position: absolute;
	right: 10px;
	top: 10px;
	width: 30px;
	height: 30px;

	color: #DDD;
	background-color: rgba(30, 30, 30, 0.4);
	border-radius: 2px;
}

.boton-camara.girar
{
	top: 43px !important;
}

.boton-camara.voltear-h
{
	top: 76px !important;
	display: none !important;
}

.boton-camara.voltear-v
{
	top: 109px !important;
	transform: rotateZ(90deg);
	display: none !important;
}

.boton-camara.recortar
{
	top: 76px !important;
	/*top: 142px !important;*/
}

.boton-camara.volver-a-subir
{
	top: 109px !important;
	/*top: 142px !important;*/
}

.boton-camara.marca-agua
{
	top: 142px !important;
	/*top: 175px !important;*/
}

.boton-camara.eliminar
{
	top: 175px !important;
	/*top: 208px !important;*/
}

.boton-camara.siguiente
{
	top: 208px !important;
}

.pie-pagina
{
	padding: 0 30px 40px 30px;
}

.pie-pagina ul li
{
	margin: 5px 0;
}

.letra-detalle-comprobante
{
	background-color: #fff;
	background-color: var(--fondo-blanco);
	color: #a59d9a;
	color: var(--fondo-cabeceras-sp);
	font-size: 120px;
	font-weight: bold;
	min-height: 300px;
	vertical-align: middle;
	padding-top: 50px;
	padding-bottom: 70px;

	border-right: #888 1px solid;
	-ms-box-shadow: 3px -0 10px 0px rgba(119,119,119,1);
	-o-box-shadow: 3px -0 10px 0px rgba(119,119,119,1);
	box-shadow: 3px -0 10px 0px rgba(119,119,119,1);
}

.letra-detalle-comprobante img
{
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	margin-bottom: 10px;
}

.datos-detalle-comprobante
{
	padding: 10px 25px;
}

.datos-detalle-comprobante h1,
.datos-detalle-comprobante h2,
.datos-detalle-comprobante h3,
.datos-detalle-comprobante h4,
.datos-detalle-comprobante h5,
.datos-detalle-comprobante h6
{
	line-height: 0.7;
	margin-top: 0px;
	margin-bottom: 0px;
}

.datos-detalle-comprobante.anulado
{
	z-index: 1000;

	padding: 30px 10px;
	background-color: tomato;
	color: white;
	opacity: 0.75;
	border-radius: 5px;

	position: fixed;

	-webkit-top: calc(100% - 265px);
	-moz-top: calc(100% - 265px);
	-ms-top: calc(100% - 265px);
	-o-top: calc(100% - 265px);
	top: calc(100% - 265px);

	left: 25%;
	width: 50%;
}

.datos-detalle-comprobante.cerrado
{
	z-index: 1000;

	padding: 30px;
	background-color: green;
	opacity: 0.75;
	border-radius: 5px;

	position: fixed;

	-webkit-top: calc(100% - 170px);
	-moz-top: calc(100% - 170px);
	-ms-top: calc(100% - 170px);
	-o-top: calc(100% - 170px);
	top: calc(100% - 170px);

	left: 25%;
	width: 50%;
}

.datos-detalle-comprobante.devuelto
{
	z-index: 1000;

	padding: 30px;
	background-color: orange;
	opacity: 0.75;
	border-radius: 5px;

	position: fixed;

	-webkit-top: calc(100% - 360px);
	-moz-top: calc(100% - 360px);
	-ms-top: calc(100% - 360px);
	-o-top: calc(100% - 360px);
	top: calc(100% - 360px);

	left: 25%;
	width: 50%;
}

.datos-detalle-comprobante.anulado h1,
.datos-detalle-comprobante.cerrado h4
{
	opacity: 0.75;
}

.menu-superior
{
	color: white;
	float: right;
	margin-right: 10px;
}

.fixed-right
{
	position: fixed;
	right: 0;
	top: 0;
	padding-top: 40px;

	height: 100vh;
	width: 35%;

	border-left: 2px solid gray;
	background-color: #fff;
	background-color: var(--fondo-blanco);
	-ms-box-shadow: -3px 0 6px 0px rgba(119, 119, 119, 1);
	-o-box-shadow: -3px 0 6px 0px rgba(119, 119, 119, 1);
	box-shadow: -3px 0 6px 0px rgba(119, 119, 119, 1);

	z-index: 100;

	overflow-y: scroll;
}

div.slide-in-right::after 
{
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: #0021f5;
}

.slide-in-right
{
	-webkit-animation: slide-in-right 0.5s forwards;
    animation: slide-in-right 0.5s forwards;
}

.slide-in-right
{
	-webkit-animation: slide-in-right 0.5s forwards;
    animation: slide-in-right 0.5s forwards;
}

.fixed-right.alargado
{
	width: inherit !important;
	max-width: 75% !important;
}

.fixed-right button#cerrar-slide,
.fixed-right button#agrandar,
.fixed-right button#achicar
{
	position: -webkit-sticky !important;
	position: sticky !important;
	top: 0px;

	background-color: white;

	z-index: 10000;
}

.slide-in-bottom
{
	-webkit-animation: slide-in-bottom 0.5s forwards;
    animation: slide-in-bottom 0.5s forwards;
}

.slide-in-top
{
	-webkit-animation: slide-in-top 0.5s forwards;
    animation: slide-in-top 0.5s forwards;
}

.slide-in-left
{
	-webkit-animation: slide-in-left 0.5s forwards;
    animation: slide-in-left 0.5s forwards;
}

.slide-out-left
{
	-webkit-animation: slide-out-left 0.5s forwards;
    animation: slide-out-left 0.5s forwards;
}

.Paginas-Consultas
{
	/*float: right;*/
	width: 100%;
	padding: 10px 0;
}

.Paginas-Consultas button
{
	padding: 5px 7px;
}

.Paginas-Consultas button.pagina-sel
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
	color: white;
	
	border-radius: 2px;
}

.versiones h5,
.versiones h2
{
	vertical-align: middle;
	margin-top: 60px;
	margin-bottom: 20px;
	padding: 10px 5px;
}

.versiones h5 .fecha,
.versiones h2 .fecha
{
	float: right;
	border-radius: 4px;
	background-color: coral;
	background-color: var(--botones-sp);
	color: white;
	font-size: 20px;

	padding: 3px 10px;
	vertical-align: middle;
}

.version svg
{
	margin-right: 10px;
	margin-top: 5px;
	vertical-align: top;
	color: coral;
	color: var(--botones-sp);
}

.versiones h2 svg,
.versiones h5 svg
{
	margin-right: 10px;
	margin-top: 0;
	vertical-align: middle;
}

.versiones h2 .fecha svg,
.versiones h5 .fecha svg
{
	margin-top: 5px;
}

.versiones h2:hover,
.versiones h5:hover
{
	background-color: rgb(218, 212, 210);
	background-color: var(--filas-tabla-par-sp);
}

.versiones h2 version,
.versiones span
{
	display:inline-flex;
}

.fs-total
{
	font-size: 1.75rem;
}

.f-raleway 
{
	font-family: 'Raleway', serif;
}

.f-oswald
{
	font-family: 'Oswald', serif;
	letter-spacing: 1px;
}

.f-lato 
{
	font-family: 'Lato', serif;
}

.f-anton 
{
	font-family: 'Anton', serif;
}

.f-pattaya
{
	font-family: 'Pattaya', serif;
}

.f-alegreya-sans
{
	font-family: 'Alegreya Sans', serif;
	font-style: italic;
}

.f-dancing-script
{
	font-family: 'Dancing Script', serif;
}

.f-sacramento
{
	font-family: 'Sacramento', serif;
}

.f-bangers
{
	font-family: 'Bangers', serif;
}

.tab
{
	font-size: 1em;
	text-align: center;

	padding: 5px 12px;
	margin-left: 5px;
	margin-top: 7px;
	color: #887c77;
	color: var(--fondo-claro-sp);

	display: inline-block;

	cursor: pointer;
}

.tab-sel
{
	color: white;
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);

	border: white 1px solid; /*var(--fondo-claro-sp)*/
	border-radius: 5px;
	-ms-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
	-o-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
	box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);

	outline:none !important;
}

.tab-nosel
{
	border: gray 1px solid; /*var(--fondo-claro-sp)*/
	border-radius: 5px;
	-ms-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
	-o-box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
	box-shadow: -3px 3px 5px 0px rgba(119, 119, 119, 1);
	
	outline:none !important;
}

.tab-cont
{
	border-top: 2px solid #887c77;
	border-top: 2px solid var(--fondo-claro-sp);
}

/* --------------------------- GENERALES --------------------------------- */
.scroll-h
{
	overflow-x: hidden;
	overflow-y: hidden;

	padding-bottom: 7px !important; 
}

.scroll-h:hover
{
	overflow-x: scroll;
	padding-bottom: 0 !important;
}

.scroll-v
{
	overflow-x: hidden;
	overflow-y: hidden;

	padding-right: 7px !important; 
}

.scroll-v:hover
{
	overflow-y: scroll;
	padding-right: 0 !important;
}

.text-interline-none
{
	line-height: inherit !important;
}

.text-interline-1
{
	line-height: 100% !important;
}

.text-interline-2
{
	line-height: 120% !important;
}

.text-interline-3
{
	line-height: 150% !important;
}

.text-interline-4
{
	line-height: 170% !important;
}

.text-interline-5
{
	line-height: 200% !important;
}

.subrayado,
.text-subrayado
{
	border-bottom: coral 3px solid;
	border-bottom: var(--botones-sp) 3px solid;
}

.text-gradient,
.text-degrade
{
	background: #333 !important;
	background: linear-gradient(45deg, rgb(81, 71, 68) 20%, coral 50%, rgb(100, 194, 194) 70%) !important;
	background: linear-gradient(45deg, var(--botones-over-sp) 20%, var(--botones-sp) 50%, var(--fondo-sp) 70%) !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.ellipsis
{
	text-overflow: ellipsis;
}

.text-spacing-1
{
	letter-spacing: 0.1em;
}

.text-spacing-2
{
	letter-spacing: 0.2em;
}

.text-spacing-3
{
	letter-spacing: 0.3em;
}

.text-spacing-4
{
	letter-spacing: 0.4em;
}

.text-spacing-5
{
	letter-spacing: 0.5em;
}

.text-grande
{
	font-size: 3rem;
}

.text-sombra-blanca
{
	text-shadow: -1px 1px #fff;
}

.text-sombra-gris
{
	text-shadow: -1px 1px #888;
}

.text-upper
{
	text-transform: uppercase;
}

.text-lower
{
	text-transform: lowercase !important;
} 

.text-esp-1
{
	letter-spacing: 1px;
}

.text-esp-2
{
	letter-spacing: 2px;
}

.text-esp-3
{
	letter-spacing: 5px;
}

.text-esp-4
{
	letter-spacing: 10px;
}

.text-esp-5
{
	letter-spacing: 20px;
}

.text-esp-1-md
{
	letter-spacing: 1px;
}

.text-esp-2-md
{
	letter-spacing: 2px;
}

.text-esp-3-md
{
	letter-spacing: 5px;
}

.text-esp-4-md
{
	letter-spacing: 10px;
}

.text-esp-5-md
{
	letter-spacing: 20px;
}

.text-smallest
{
	font-size: 0.55rem !important;
}

.text-smaller
{
	font-size: 0.75rem !important;
}

.text-small
{
	font-size: 1rem !important;
}

.text-normal
{
	font-size: 1.1rem !important;
}

.text-medium
{
	font-size: 2rem !important;
}

.text-high
{
	font-size: 3rem !important;
}

.text-big
{
	font-size: 1rem !important;
}

.text-bigger
{
	font-size: 4rem !important;
}

.text-bottom
{
	vertical-align: text-bottom;
	height: 100%;
}

.text-gray
{
	color: gray !important;
}

.text-black
{
	color: black !important;
}

.text-blue
{
	color: blue !important;
}

.text-light-gray,
.text-disabled
{
	color: rgb(211, 211, 211) !important;
}

.text-green
{
	color: green !important;
}

.text-sp,
.text-sp svg 
{
	color: rgb(100, 194, 194) !important;
	color: var(--fondo-sp) !important;
}

.text-yellow,
.text-yellow svg
{
	color: #fff200 !important;
}

.text-red,
.text-red svg
{
	color: rgb(255, 90, 90) !important;
	color: var(--rojo) !important;
}

.text-destacado,
.text-destacado svg,
.text-resaltado,
.text-resaltado svg
{
	color: coral !important;
	color: var(--botones-sp) !important;
}

.tachado,
.text-tachado
{
	text-decoration: line-through;
}

.bold,
.font-bold,
.text-bold,
.negrita
{
	font-weight: bolder !important;
}

.font-normal,
.text-normal,
.normal
{
	font-weight: normal;
}

table td.fondo-sp button.text-gray,
table td.fondo-rojo button.text-gray,
table td.fondo-resaltado button.text-gray
{
	color: white !important;
}

select.text-white option 
{
	color: gray;
}

select.text-white option.requerido 
{
	color: coral;
	color: var(--botones-sp);
}

.MuiSwitch-track
{
	background-color: rgb(81, 71, 68) !important;
	background-color: var(--fondo-oscuro-sp) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track
{
	background-color: coral !important;
	background-color: var(--botones-sp) !important;
}

.MuiSwitch-colorPrimary.Mui-checked
{
	background-color: rgba(255, 127, 80, 0.2);
	color: coral !important;
	color: var(--botones-sp) !important;
}

.separador
{
	border-top: 1px solid #DDD;
	padding: 0;
	margin-top: 20px;
	margin-bottom: 10px;
}

.transparent
{
	background-color: rgba(0, 0, 0, 0) !important;
}

.opaco
{
	opacity: 1;
}

.blanco
{
	background-color: #fff;
	background-color: var(--fondo-blanco);
	background: #fff;
	background: var(--fondo-blanco);
}

.gris
{
	background-color: rgb(175, 165, 161);
	background-color: var(--filas-tabla-sp);
	background: rgb(175, 165, 161);
	background: var(--filas-tabla-sp);
}

.sin-borde,
.sin-borde input,
.sin-borde select,
.sin-borde textarea,
.border-none,
.border-none input,
.border-none select,
.border-none textarea
{
	border: 0 !important;
}

.sin-borde input:disabled,
.sin-borde select:disabled,
.border-none:disabled,
.border-none input:disabled,
.border-none select:disabled
{
	background-color: white;
	color: rgb(175, 165, 161);
}

.borde-blanco,
.border-white
{
	border: 1px solid white;
}

.border-gray,
.borde-gris
{
	border: 1px solid gray;
}

.border-gradient,
.borde-degrade
{
	border: 10px solid transparent; 
	border-image: linear-gradient(to bottom, #ff0000 0%, #0021f5 100%) 1;
}

.border-gradient-naranja,
.borde-degrade-naranja
{
	border: 10px solid transparent; 
	border-image: linear-gradient(to left bottom, coral 0%, gray 20%, coral 100%) 1; 
	border-image: linear-gradient(to left bottom, var(--botones-sp) 0%, gray 20%, var(--botones-sp) 100%) 1;
}

.border-green,
.borde-verde
{
	border: 1px solid green;
}

.borde-negro,
.border-black
{
	border: 1px solid black;
}

.borde-rojo,
.border-rojo,
.border-red
{
	border: 1px solid tomato;
}

.borde-sp,
.border-sp
{
	border: 1px solid rgb(100, 194, 194);
	border: 1px solid var(--fondo-sp);
}

.borde-resaltado,
.border-resaltado
{
	border: 1px solid coral;
	border: 1px solid var(--botones-sp);
}

.borde-b 
{
	border-bottom: #888 solid 1px !important;
}

.borde-b-dot,
.border-b-dot
{
	border-bottom: #888 dotted 1px !important;
}

.borde-dot,
.border-dot
{
	border: #888 dotted 1px !important;
}

.borde-l-dot,
.border-l-dot
{
	border-left: #888 dotted 1px !important;
}

.borde-t-dot,
.border-t-dot
{
	border-top: #888 dotted 1px !important;
}

.border-radius-0,
.borde-radius-0
{
	border-radius: 0px !important;
}

.border-radius-3,
.borde-radius-3
{
	border-radius: 3px !important;
}

.border-radius-5,
.borde-radius-5
{
	border-radius: 5px !important;
}

.border-radius-50p,
.borde-radius-50p
{
	border-radius: 50% !important;
}

.border-radius-10,
.borde-radius-10
{
	border-radius: 10px !important;
}

.border-1 
{
	border-width: 1px;
}

.border-2 
{
	border-width: 2px;
}

.border-3 
{
	border-width: 3px;
}

.border-4 
{
	border-width: 4px;
}

.border-5 
{
	border-width: 5px;
}

.cursor-pointer
{
	cursor: pointer;
}

.input-control.border-none h6,
.input-control.sin-borde h6,
h6.titulo-input
{
	font-weight: 700;
	color: rgb(100, 194, 194);
	color: var(--fondo-sp);
}

.input-control.border-none.text-red h6,
.input-control.sin-borde.text-red h6,
h6.titulo-input.text-red
{
	font-weight: 700;
	color: rgb(255, 90, 90);
	color: var(--rojo);
}

.input-control.border-none svg,
.input-control.sin-borde svg
{
	color: #887c77;
	color: var(--fondo-claro-sp);
}

.buscador.text-input-high,
.input-control.text-input-high
{
	font-size: 2.5rem !important;
}

.buscador.text-input-high input,
.input-control.text-input-high input
{
	height: 50px !important;
}

.buscador.text-input-high svg,
.buscador.text-input-high button
{
	font-size: 1rem !important;
	margin-bottom: 20px !important;
}

.buscador.text-input-high button.comun svg
{
	margin-bottom: 0 !important;
}

.buscador.text-input-high .dropdown svg
{
	margin-bottom: 0px !important;
	margin-top: -60px !important;
}

.sticky-l
{
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 11;
}

td.sticky-l
{
	background-color: rgb(218, 212, 210);
	background-color: var(--filas-tabla-par-sp);
	/*font-weight: bold;*/
}

.sticky-l-115
{
	left: 115px;
}

.sticky-l-135
{
	left: 135px;
}

.sticky-l-160
{
	left: 160px;
}

.sticky-l-175
{
	left: 175px;
}

.sticky-l-200
{
	left: 200px;
}

.sticky-v
{
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 15 !important;
	float: none !important;
}

.sticky-b
{
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	z-index: 15 !important;
	float: none !important;
}

.sticky-v-40
{
	top: 40px;
	z-index: 15 !important;
	height: 90vh;
}

.swal-content
{
	color: gray; 
	max-height: 350px;
	overflow-y: scroll;
	max-width: 800px;
	margin: auto;
}

.h-50-vh
{
	height: 50vh !important;
}

.h-75-vh
{
	height: 75vh !important;
}

.h-80-vh
{
	height: 80vh !important;
}

.h-100-vh
{
	height: 100vh !important;
}

.h-100-p
{
	height: 100% !important;
}

.overflow-visible
{
	overflow: visible;
}

.overflow-ellipsis
{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
}

.overflow-hidden
{
	overflow: hidden;
}

/*.h-50
{
	height: 50px;
	max-height: 50px;
}*/

.h-100
{
	height: 100px !important;
	max-height: 100px;
}

.h-150
{
	height: 150px;
	max-height: 150px;
}

.h-200
{
	height: 200px;
	max-height: 200px;
}

.h-220
{
	height: 220px;
	max-height: 220px;
}

.h-250
{
	height: 250px;
	max-height: 250px;
}

.h-300
{
	height: 300px;
	max-height: 300px;
}

.swal-content button
{
	color: gray;
	margin-bottom: 5px;
}

.swal-content h6
{
	border-bottom: solid gray 1px;
	padding-bottom: 10px;
}

.swal-modal {
    width: inherit;
}

.btn-primary,
.btn-secundary 
{
	border-radius: 25px !important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle 
{
    color: #fff;
    background-color: coral;
    background-color: var(--botones-sp);
    border-color: coral;
    border-color: var(--botones-sp);
}

.aux-1
{
	height: 1px;
}

.aux-2
{
	height: 2px;
}

.aux-3
{
	height: 3px;
}

.absolute-bl
{
	position: absolute;
	bottom: 0;
	left: 0;
}

.absolute-br
{
	position: absolute;
	bottom: 0;
	right: 0;
}

.absolute-tl
{
	position: absolute;
	top: 0;
	left: 0;
}

.absolute-tr
{
	position: absolute;
	top: 0;
	right: 0;
}

.flecha-scroll-der
{
	position: -webkit-sticky;
	position: sticky;
	top: calc(50% - 10px);
	left: 0;
	z-index: 1000;
}

.flecha-scroll-izq 
{
	position: -webkit-sticky;
	position: sticky;
	float: right;
	top: calc(50% - 10px);
	right: 0;
	z-index: 1000;
}

.carrito-landing
{
	position: absolute;
	z-index: 1000;

	width: 70%;
	left: 100px;
	top: 250px;
}

.carrito-landing table
{
	width: 100%;
}

.min-height-145
{
	min-height: 145px !important;
}

.min-height-100
{
	min-height: 100px !important;
}

.min-height-200
{
	min-height: 200px !important;
}

.wpx-10
{
	width: 10px !important;
	height: inherit;
}

.wpx-20
{
	width: 20px !important;
	height: inherit;
}

.wpx-25
{
	width: 25px !important;
	height: inherit;
}

.wpx-40
{
	width: 40px !important;
	height: inherit;
}

.wpx-30
{
	width: 30px !important;
	height: inherit;
}

.wpx-50
{
	width: 50px !important;
	height: inherit;
}

.wpx-75
{
	width: 75px !important;
	height: inherit;
}

.wpx-80
{
	width: 80px !important;
	height: inherit;
}

.wpx-100
{
	width: 100px !important;
	height: inherit;
}

.wpx-120
{
	width: 120px !important;
	height: inherit;
}

.wpx-150
{
	width: 150px !important;
	height: inherit;
}

.wpx-170
{
	width: 170px !important;
	height: inherit;
}

.wpx-200
{
	width: 200px !important;
	height: inherit;
}

.wpx-250
{
	width: 250px !important;
	height: inherit;
}

.wpx-300
{
	width: 300px !important;
	height: inherit;
}

.wpx-350
{
	width: 350px !important;
	height: inherit;
}

.wpx-400
{
	width: 400px !important;
	height: inherit;
	overflow-x: hidden;
}

.wpx-500
{
	width: 500px !important;
	height: inherit;
	overflow-x: hidden;
}

.hpx-10
{
	height: 10px !important;
	width: inherit;
}

.hpx-20
{
	height: 20px !important;
	width: inherit;
}

.hpx-25
{
	height: 25px !important;
	width: inherit;
}

.hpx-30
{
	height: 30px !important;
	width: inherit;
}

.hpx-50
{
	height: 50px !important;
	width: inherit !important;
}

.hpx-75
{
	height: 75px !important;
	width: inherit !important;
}

.hpx-80
{
	height: 80px !important;
	width: inherit !important;
}

.hpx-100
{
	height: 100px !important;
	width: inherit;
}

.hpx-120
{
	height: 120px !important;
	width: inherit;
}

.hpx-150
{
	height: 150px !important;
	min-height: 150px !important;
	max-height: 150px !important;
}

.hpx-170
{
	height: 170px !important;
	width: inherit;
}

.hpx-180
{
	height: 180px !important;
	min-height: 180px !important;
	max-height: 180px !important;
}

.hpx-200
{
	height: 200px !important;
	min-height: 200px !important;
	max-height: 200px !important;
}

.hpx-300
{
	height: 300px !important;
	min-height: 300px !important;
	max-height: 300px !important;
}

.logo-possum
{
	text-align: center;
}

/* ------------------ REPORTES ---------------------------------------------------- */

.reportes
{
	width: calc(100vw - 80px);
	margin-left: -27px;

	padding: 0 1em 1em 1em;
	border: 0;
	background-color: #CCC;

	overflow: hidden;
}

.reportes-emb
{
	width: calc(100vw - 80px);
	height: calc(100vh - 40px) !important;
	margin-left: -27px; 
	top: 40px;

	padding: 0 1em 3em 1em;
	border: 0;
	background-color: #CCC;

	overflow: scroll;
	z-index: 20;
	position: absolute;
}

.hoja-a4
{
	margin-top: 15px;

	height: 27cm;
	width: 21cm; 
	box-shadow: 5px 8px 15px 5px #333;
	color: black;
	background-color: #fff;
	background-color: var(--fondo-blanco);

	margin: auto;
	overflow: hidden;
}

.hoja-ticket
{
	margin-top: 15px;

	height: 27cm;
	width: 8cm; 
	box-shadow: 5px 8px 15px 5px #333;
	color: black;
	background-color: #fff;
	background-color: var(--fondo-blanco);

	margin: auto;
	overflow: hidden;
}

.pie-reportes
{
	padding-top: 15px;
}

.pie-reportes-mediano 
{
    padding-top: 75px !important;
}

.pie-reportes-grande
{
	padding-top: 125px !important;
}

.pie-reportes-chico
{
    padding-top: 15px !important;
}

.pie-reportes img.qr-afip
{
	margin-top: -15px;
	margin-left: -30px;
} 

.flex-sm-column,
.flex-xs-column
{
	flex-direction: unset !important;
} 

.flex-column
{
	flex-direction: column !important;
}

.justify-content-sm-start 
{
	justify-content: center;
}

.justify-content-center
{
	justify-content: center !important;
}

.scroll-h::-webkit-scrollbar,
.scroll-v::-webkit-scrollbar  
{
	width: 7px;
	height: 7px !important;
}
   
.scroll-h::-webkit-scrollbar-track,
.scroll-v::-webkit-scrollbar-track  
{
	box-shadow: inset 0 0 3px rgba(200, 200, 200, 0.8);
}
   
.scroll-h::-webkit-scrollbar-thumb,
.scroll-v::-webkit-scrollbar-thumb  
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp); 
}

nav::-webkit-scrollbar  
{
	background-color: rgb(100, 194, 194);
	background-color: var(--fondo-sp);
	width: 1px !important;
}

.nav::-webkit-scrollbar-track  
{
	box-shadow: inset 0 0 3px rgba(200, 200, 200, 0.8);
}

nav::-webkit-scrollbar-thumb  
{
	background-color: gray;
	outline: 1px solid slategrey;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line
{ 
  	-webkit-text-fill-color: rgb(114, 114, 114);
  	box-shadow: 0 0 0px 1000px transparent inset;
  	-webkit-transition: background-color 5000s ease-in-out 0s;
  	transition: background-color 5000s ease-in-out 0s;
} 
@media screen and (max-width: 740px)
{
    .grilla-consulta
    {
        width: 5px !important;
        margin: 1px 0 0 1px;
        padding-left: 2px;
        padding-right: 2px;
    }

    .Contenedor
    {
        padding: 40px 10px 0 10px;
    }

    nav
    {
        position: fixed;
        left: 0;
        top: 0;
        height: 40px; 
        overflow-y: hidden;
    }

    nav:hover 
    {
        width: 85%;
    }

    .inferior
    {
        display: none; 
    }

    nav .superior
    {
        display: block;
    }

    .inferior.inferior-abierto
    {
        display: block;
        width: 84%;
    }

    .Cabecera
    {
        text-align: center;
        vertical-align: middle;
        line-height: 25px;
        font-size: 13px;
        margin-left: 0;
        padding-left: 45px;
    }

    .__react_component_tooltip.show
    {
        display: none !important;
    }

    .flotante
    {
        width: 350px;
        min-width: 350px;
        
        left: calc(50% - 175px);
        -o-left: calc(50% - 175px);
        -ms-left: calc(50% - 175px);
        -moz-left: calc(50% - 175px);
        -webkit-left: calc(50% - 175px);
        
        top: calc(50% - 300px); 
        -o-top: calc(50% - 300px); 
        -ms-top: calc(50% - 300px); 
        -moz-top: calc(50% - 300px); 
        -webkit-top: calc(50% - 300px); 
    }

    .flotante-chico
    {
        width: 320px;
        min-width: 320px;

        left: calc(50% - 160px); 
        -o-left: calc(50% - 160px); 
        -ms-left: calc(50% - 160px); 
        -moz-left: calc(50% - 160px); 
        -webkit-left: calc(50% - 160px);

        top: calc(50% - 200px); 
        -o-top: calc(50% - 200px); 
        -ms-top: calc(50% - 200px); 
        -moz-top: calc(50% - 200px); 
        -webkit-top: calc(50% - 200px); 
    }

    .logo-principal img 
    {
        margin: 10px;
    }

    .logo-principal h1
    {
	    margin-top: 6px;
	    margin-right: 20px;

        font-size: 10px;
        letter-spacing: 4px;

        padding-right: 0;
    }

    .logo-principal h1 span
    {
        font-size: 10px;
        letter-spacing: 1px;
    }

    .logo-principal h1 p
    {
        font-size: 8px;
        letter-spacing: 0;
    }

    .contenedor-accesos-directos
    {
        padding: 40px 10px 0 5px;
        text-align: center;
    }

    .acceso-directo
    {
        width: 130px;
        font-size: 30px;
        min-height: 150px;
        max-height: 150px;
    }

    .acceso-directo .tarjeta-anuncios 
    {
        padding: 5px !important; 
        max-height: 125px !important;
        min-height: 110px !important;
    }

    .dropdown-menu
    {
        left: 50px;
    }

    .botones-confirmar 
    {
        left: 0;
    }

    .buscador .reg-encontrados .centrados,
    .buscador .reg-encontrados .centrados.grande
    {
        width: 90%;
        height: 91%;
        left: 5%;
        top: 6%;
    }

    .buscador .icono-productos
    {
        width: 90px;
    }

    .buscador .codigo-buscador
    {
        width: 0;
    }

    .buscador .dropdown-menu
    {
        margin-top: -24px;
        margin-left: -200px;
        width: inherit;
    }

    .tipar-input select.col-xs-10 
    {
        width: 83.33333333% !important;
    } 

    .input-control input[type="date"]
    {
        background-color: white;
    }

    .buscador input.descrip 
    {
        width: 100%;
    }

    .buscador input.codigo 
    {
        width: 100%;
    }

    .detalle-fp-lista
    {
        padding: 0 !important;
    }

    .scroll-h
    {
        overflow-x: scroll;
        padding-bottom: 0 !important;
    }

    .scroll-v
    {
        overflow-y: scroll;
    }

    .scroll-h::-webkit-scrollbar-track,
    .scroll-v::-webkit-scrollbar-track  
    {
        box-shadow: inset 0 0 3px rgba(200, 200, 200, 0);
    }

    .scroll-h::-webkit-scrollbar,
    .scroll-v::-webkit-scrollbar  
    {
        width: 3px !important;
    }

    .buscador-abm
    {
        padding-left: 15px;
    }
    
    .abm-detalle
    {
        margin-right: 5px;
        padding-bottom: 10px;
    }
    
    .abm-detalle div.foto-abm-detalle
    {
        min-height: inherit;
    }

    .pie-pagina ul,
    .pie-pagina .logo-pie
    {
        padding-left: 0;
    }

    .filtro-consulta,
    .admin-producto,
    .comprobante-cabecera
    {
        margin-left: 7px;
        margin-right: 2px;
    }

    .letra-detalle-comprobante
    {
        min-height: 220px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
    .letra-detalle-comprobante img
    {
        width: auto;
        height: 50px;
        
        -webkit-left: calc(50% - 109px);
        -moz-left: calc(50% - 109px);
        -ms-left: calc(50% - 109px);
        -o-left: calc(50% - 109px);
        left: calc(50% - 109px);
    }

    .datos-detalle-comprobante span 
    {
        display: block;
        width: 100%;
    }
    
    .datos-detalle-comprobante h1,
    .datos-detalle-comprobante h2,
    .datos-detalle-comprobante h3,
    .datos-detalle-comprobante h4,
    .datos-detalle-comprobante h5,
    .datos-detalle-comprobante h6
    {
        line-height: 1;
    }

    .datos-detalle-comprobante.anulado
    {
        -webkit-top: calc(100% - 415px);
        -moz-top: calc(100% - 415px);
        -ms-top: calc(100% - 415px);
        -o-top: calc(100% - 415px);
        top: calc(100% - 415px);

        left: 10%;
        width: 80%;
    }

    .datos-detalle-comprobante.cerrado
    {
        -webkit-top: calc(100% - 250px);
        -moz-top: calc(100% - 250px);
        -ms-top: calc(100% - 250px);
        -o-top: calc(100% - 250px);
        top: calc(100% - 250px);

        left: 10%;
        width: 80%;
    }
        
    .collapse .card-body
    {
        padding: 1rem 1rem 1rem 0.2rem;
    }
    
    .tarjeta
    {
        margin-left: 15px;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 20px;
    }

    .sticky-l
    {
        position: relative;
        left: inherit;
        z-index: inherit;
    }

    .sticky-l-115
    {
        position: relative;
        left: inherit;
        z-index: inherit;
    }

    .sticky-l-160
    {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 10;
        max-width: 100px;
        overflow: hidden;
    }

    .versiones
    {
        padding-left: 30px;
    }
    
    .type-success 
    {
        max-width: 300px;
    }

    .fixed-right 
    {
        width: 75%;
    }

    .fs-total
    {
        font-size: 1.45rem !important;
    }

    .text-xs-center
    {
        text-align: center !important;
    }

    .text-xs-right
    {
        text-align: right !important;
    }

    .px-xs-0
    {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .px-xs-1
    {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-xs-2
    {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-xs-3
    {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-xs-4
    {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-xs-5
    {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .pl-xs-0
    {
        padding-left: 0 !important;
    }

    .pr-xs-0
    {
        padding-right: 0 !important;
    }

    .mt-xs-0
    {
        margin-top: 0 !important;
    }

    .mb-xs-0
    {
        margin-bottom: 0 !important;
    }

    .mt-xs-1
    {
        margin-top: 1rem !important;
    }

    .mt-xs-4
    {
        margin-top: 2.1rem !important;
    }

    .mb-xs-1
    {
        margin-bottom: 1rem !important;
    }

    .mb-xs-2
    {
        margin-bottom: 0.5rem !important;
    }

    .fixed-right.alargado 
    {
        max-width: calc(100% - 62px) !important;
        -o-max-width: calc(100% - 62px) !important;
        -ms-max-width: calc(100% - 62px) !important;
        -moz-max-width: calc(100% - 62px) !important;
        -webkit-max-width: calc(100% - 62px) !important;
    }

    .tab
    {   
        display: block;
        margin-bottom: 5px;
    }

    .tab-sel
    {
        border-radius: 5px;
        border: white 1px solid; /*var(--fondo-claro-sp)*/
    }

    .ml-xs-0
    {
        margin-left: 0 !important;
    }

    .mx-xs-0
    {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .absolute-bl
    {
        position: relative;
    }

    .absolute-br
    {
        position: relative;
    }

    .absolute-tl
    {
        position: relative;
    }

    .absolute-tr
    {
        position: relative;
    }

    .p-xs-0
    {
        padding: 0 !important;
    }

    .tarjeta-anuncios
    {
        width: 100%;
        min-height: 110px;
        padding: 15px 10px 10px 10px !important;
    }

    .tarjeta-anuncios.height-auto
    {
        min-height: inherit;
    }

    .aviso-inferior div
    {
        padding: 9px 10px 8px 10px;
    }

    .aviso-inferior span
    {
        display: block;
    }
    
    .ctacte-sp
    {
        padding-left: 0;
    }

    .ctacte-sp #datos-pagar ul
    {
        padding-left: 0;
    }

    .ctacte-sp #datos-bb,
    .ctacte-sp #datos-bc,
    .ctacte-sp #datos-otr
    {
        margin-left: 0 !important;
    }

    .text-grande
    {
        font-size: 2rem;
    }

    .text-esp-1-md
    {
        letter-spacing: inherit;
    }

    .text-esp-2-md
    {
        letter-spacing: inherit;
    }

    .text-esp-3-md
    {
        letter-spacing: inherit;
    }

    .text-esp-4-md
    {
        letter-spacing: inherit;
    }

    .text-esp-5-md
    {
        letter-spacing: inherit;
    }

    .tarjetas-pagar
    {
        margin-top: 0;
    }

    .reportes
    {
        width: 100vw;
        margin-left: -9px;
        padding: 0;

        overflow: scroll;
    }

    .xs-invisible,
    .invisible-xs
    {
        display: none;
    }
   
    .md-invisible,
    .invisible-md
    {
        visibility: visible !important;
    }
    
    .xs-h-50-vh
    {
        height: 50vh !important;
    }

    .justify-content-sm-start, 
    .justify-content-xs-start 
    {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end, 
    .justify-content-xs-end 
    {
        justify-content: flex-end !important;
    }
    
    .flex-sm-column,
    .flex-xs-column
    {
        flex-direction: column !important;
    }

    .min-xs-height,
    .min-sm-height-145
    {
        min-height: 145px !important;
    }

    .min-xs-height,
    .min-sm-height-100
    {
        min-height: 100px !important;
    }

    .min-xs-height,
    .min-sm-height-200
    {
        min-height: 200px !important;
    }

    .wpx-xs-10,
    .wpx-sm-10
    {
        width: 10px !important;
        height: inherit;
    }

    .wpx-xs-20,
    .wpx-sm-20
    {
        width: 20px !important;
        height: inherit;
    }

    .wpx-xs-25,
    .wpx-sm-25
    {
        width: 25px !important;
        height: inherit;
    }

    .wpx-xs-30,
    .wpx-sm-30
    {
        width: 30px !important;
        height: inherit;
    }

    .wpx-xs-50,
    .wpx-sm-50
    {
        width: 50px !important;
        height: inherit;
    }

    .wpx-xs-75,
    .wpx-sm-75
    {
        width: 75px !important;
        height: inherit;
    }

    .wpx-xs-100,
    .wpx-sm-100
    {
        width: 100px !important;
        height: inherit;
    }

    .wpx-xs-120,
    .wpx-sm-120
    {
        width: 120px !important;
        height: inherit;
    }

    .wpx-xs-150,
    .wpx-sm-150
    {
        width: 150px !important;
        height: inherit;
    }

    .wpx-xs-170,
    .wpx-sm-170
    {
        width: 170px !important;
        height: inherit;
    }

    .wpx-xs-200,
    .wpx-sm-200
    {
        width: 200px !important;
        height: inherit;
    }

    .wpx-xs-250,
    .wpx-sm-250
    {
        width: 250px !important;
        height: inherit;
    }

    .wpx-xs-300,
    .wpx-sm-300
    {
        width: 300px !important;
        height: inherit;
    }

    .wpx-xs-350,
    .wpx-sm-350
    {
        width: 350px !important;
        height: inherit;
    }

    .wpx-xs-400,
    .wpx-sm-400
    {
        width: 250px !important;
        height: inherit;
        overflow-x: hidden;
    }

    .hpx-xs-10,
    .hpx-sm-10
    {
        height: 10px !important;
        width: inherit;
    }

    .hpx-xs-20,
    .hpx-sm-20
    {
        height: 20px !important;
        width: inherit;
    }

    .hpx-xs-25,
    .hpx-sm-25
    {
        height: 25px !important;
        width: inherit;
    }

    .hpx-xs-30,
    .hpx-sm-30
    {
        height: 30px !important;
        width: inherit;
    }

    .hpx-xs-50,
    .hpx-sm-50
    {
        height: 50px !important;
        width: inherit !important;
    }

    .hpx-xs-100,
    .hpx-sm-100
    {
        height: 100px !important;
        width: inherit;
    }

    .hpx-xs-120,
    .hpx-sm-120
    {
        height: 120px !important;
        width: inherit;
    }

    .hpx-xs-150,
    .hpx-sm-150
    {
        height: 150px !important;
        min-height: 150px !important;
        max-height: 150px !important;
    }

    .hpx-xs-170,
    .hpx-sm-170
    {
        height: 170px !important;
        width: inherit;
    }

    .hpx-xs-180,
    .hpx-sm-180
    {
        height: 180px !important;
        min-height: 180px !important;
        max-height: 180px !important;
    }

    .hpx-xs-200,
    .hpx-sm-200
    {
        height: 200px !important;
        min-height: 200px !important;
        max-height: 200px !important;
    }

    .Cabecera-titulo
    {
        font-size: 8px;
        text-align: right;
        line-height: 15px;
    }

    .usuario-gr h4,
    .usuario-gr h6
    {
        max-width: 350px;
    }

    .text-xs-input-small input
    {
        font-size: 12px !important;
        height: 15px !important;
        max-height: 15px !important;
        min-height: 15px !important;
    }

    .detalle-prod .opciones-stock .text-nowrap
    {
        text-wrap: wrap !important;
    }

    .fondo-degrade-gray-t
    {
        background: rgb(170,170,170);
        background: linear-gradient(180deg, rgba(170,170,170,1) 6%, rgba(0,212,255,0) 13%, rgba(0,0,0,0) 100%);
    }
}
@media screen and (max-width: 1150px)
{
    .acceso-directo .tarjeta-anuncios 
    {
        padding: 5px !important;
        min-height: 165px;
    }
    
    .acceso-directo h2
    {
        overflow-wrap: break-word !important;
    }

    .landing-pagar
    {
        background: url('/images/pagar.jpg');
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media screen and (min-width: 742px) and (max-width: 900px)
{
    .acceso-directo .tarjeta-anuncios 
    {
        padding: 5px !important;
        min-height: 165px;
    }
    
    .acceso-directo h2
    {
        overflow-wrap: break-word !important;
    }

    .col-md-6 {
        flex: 0 0 40%;
        max-width: 40%;
        width: 40%;
    }

    .landing-page video
    {
        width: 100%;
    }

    .landing-page video.vertical
    {
        width: 100vw;
        height: auto !important;
    }
} 
@media screen and (max-width: 800px)
{
    .flex-sm-column,
    .flex-xs-column
    {
        flex-direction: column !important;
    }

    .principal .informes
    {
        flex: 0 0 100% !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}
/* ---------- RESALTAR -------------------------------------------------------*/

@keyframes Resaltar
{
	0%
	{
		translate: -100%;
		transform: scale(1);
	}

	25%
	{
		transform: scale(1.07);
	}

	50%
	{
		translate: -100%;
		transform: scale(1);
	}

	75%
	{
		transform: scale(1.07); 
	}

	100%
	{
		transform: scale(1);
	}
}

@-webkit-keyframes Resaltar
{
	0%
	{
		translate: -100%;
		transform: scale(1);
	}

	25%
	{
		transform: scale(1.07);
	}

	50%
	{
		translate: -100%;
		transform: scale(1);
	}

	75%
	{
		transform: scale(1.07);
	}

	100%
	{
		transform: scale(1);
	}
}

/* ---------- RESALTAR SEL -------------------------------------------------------*/

@keyframes ResaltarSel
{
	0%
	{
		transform: scale(1);
	}

	33%
	{
		transform: scale(1.2);
	}

	66%
	{
		transform: scale(1);
	}

	100%
	{
		transform: scale(1.2);
	}
}

@-webkit-keyframes ResaltarSel
{
	0%
	{
		transform: scale(1);
	}

	33%
	{
		transform: scale(1.2);
	}

	66%
	{
		transform: scale(1);
	}

	100%
	{
		transform: scale(1.2);
	}
}

/* ---------- APARECER SLIDE -------------------------------------------------------*/

@keyframes AparecerSlide
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

@-webkit-keyframes AparecerSlide
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

/* ---------- SLIDE IN RIGHT -------------------------------------------------------*/

@keyframes slide-in-right 
{
	0% { right: -100%; }
    100% { right: 0; }
}

@-webkit-keyframes slide-in-right 
{
	0% { right: -100%; }
    100% { right: 0; }
}

/* ---------- SLIDE OUT RIGHT -------------------------------------------------------*/

@keyframes slide-out-right 
{
	0% { right: 0; }
    100% { right: -100%; }
}

@-webkit-keyframes slide-out-right 
{
	0% { right: 0; }
    100% { right: -100%; }
}

/* ---------- SLIDE IN LEFT -------------------------------------------------------*/

@keyframes slide-in-left 
{
	0% { left: -100%; }
    100% { left: 0; }
}

@-webkit-keyframes slide-in-left 
{
	0% { left: -100%; }
    100% { left: 0; }
}

/* ---------- SLIDE OUT LEFT -------------------------------------------------------*/

@keyframes slide-out-left 
{
	0% { left: 0; }
    100% { left: -100%; }
}

@-webkit-keyframes slide-out-left 
{
	0% { left: 0; }
    100% { left: -100%; }
}

/* ---------- SLIDE IN BOTTOM -------------------------------------------------------*/

@keyframes slide-in-bottom 
{
	0% { bottom: -100%; }
    100% { bottom: 0; }
}

@-webkit-keyframes slide-in-bottom 
{
	0% { bottom: -100%; }
    100% { bottom: 0; }
}

/* ---------- SLIDE IN TOP -------------------------------------------------------*/

@keyframes slide-in-top 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-webkit-keyframes slide-in-top 
{
	0% { top: -100%; }
    100% { top: 0; }
}

/* ---------- SLIDE IN TOP -------------------------------------------------------*/

@keyframes slide-in-top-menu 
{
	0% { top: -100%; }
    100% { top: 0; }
}

@-webkit-keyframes slide-in-top-menu 
{
	0% { top: -100%; }
    100% { top: 0; }
}

/* ---------- ACHICAR CABECERA -------------------------------------------------------*/

@keyframes AchicarCabeceraComp
{
	0% { transform: scaleY(3); }
    100% { transform: scaleY(1); }
}

@-webkit-keyframes AchicarCabeceraComp
{
	0% { transform: scaleY(3); }
    100% { transform: scaleY(1); }
}

/* ---------- GIRAR CHECKS -------------------------------------------------------*/

@-webkit-keyframes Girar-Check
{
	0%
	{
		border-radius: 6px;
	}

	100%
	{
		border-radius: 12px;
	}
}

@keyframes Girar-Check
{
	0%
	{
		border-radius: 6px;
	}

	100%
	{
		border-radius: 12px;
	}
}

/* ---------- CARRUSEL DER a IZQ -------------------------------------------------------*/

@-webkit-keyframes carrusel-izq 
{
	0% 
	{
		opacity: 0.8;
		transform: translate3d(500%, 0, 0);
	}

	100% 
	{
		opacity: 1;
		transform: none;
	}
}

@keyframes carrusel-izq 
{
	0% {
		opacity: 0.8;
		transform: translate3d(500%, 0, 0);
	}
	
	100% 
	{
		opacity: 1;
		transform: none;
	}
} 

/* ---------- BOUNCE -------------------------------------------------------*/

@-webkit-keyframes bounceIn 
{
	0%, 20%, 40%, 60%, 80%, 100% 
	{
		transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	
	0% 
	{
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}
	
	20% 
	{
		transform: scale3d(1.1, 1.1, 1.1);
	}
	
	40% 
	{
		transform: scale3d(.9, .9, .9);
	}

	60% 
	{
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% 
	{
		transform: scale3d(.97, .97, .97);
	}
	
	100% 
	{
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

@keyframes bounceIn 
{
	0%, 20%, 40%, 60%, 80%, 100% 
	{
		transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	
	0% 
	{
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	20% 
	{
		transform: scale3d(1.3, 1.3, 1.3);
	}

	40% {
		transform: scale3d(.9, .9, .9);
	}
	
	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}
	
	80% {
		transform: scale3d(.97, .97, .97);
	}
	
	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
} 
/*@font-face {
				font-family: 'Rajdhani';
				src: local('Rajdhani'), url(./fonts/Rajdhani/Rajdhani-Regular.ttf) format('truetype');
			}*/
:root 
{	
	--oscuro-fondo-secundario-claro-sp: rgb(163, 203, 187);
	--oscuro-fondo-sp: rgb(100, 194, 194);
	--oscuro-fondo-claro-sp: #887c77; /*rgb(114, 104, 100); */
	--oscuro-fondo-cabeceras-sp: #a59d9a; 
	--oscuro-fondo-oscuro-sp: rgb(81, 71, 68); 
	--oscuro-fondo-semi-oscuro-sp: rgb(145, 128, 123);
	--oscuro-fondo-secundario-sp: rgb(128, 126, 134);
	--oscuro-fondo-secundario-oscuro-sp: rgb(164, 162, 170);
	--oscuro-fondo-secundario-resaltar-sp: rgb(148, 144, 161);
	--oscuro-fondo-totalizadores-sp: rgb(223, 206, 201);
	--oscuro-botones-sp: coral;
	--oscuro-botones-over-sp: rgb(81, 71, 68);
	--oscuro-botones-secundarios-sp: rgb(100, 194, 194);
	--oscuro-botones-secundarios-hover-sp: rgb(116, 107, 104);
	--oscuro-grupos-sp: rgb(143, 138, 136);
	--oscuro-grupos-hover-sp: rgb(81, 71, 68); 
	--oscuro-filas-tabla-sp: rgb(175, 165, 161); /*rgb(206, 158, 140);*/
	--oscuro-filas-tabla-par-sp: rgb(218, 212, 210); 
	--oscuro-filas-tabla-impar-sp: rgb(81, 71, 68);  
	--oscuro-rojo: rgb(255, 90, 90); 
	--oscuro-verde: rgb(144, 202, 51); 
	--oscuro-fondo-blanco: #0f1e29;
}

.tema-oscuro,
.tema-oscuro pre
{
	color: white;
}

.tema-oscuro .Contenedor,
html, #root
{
	background: rgb(2,0,36);
	background: linear-gradient(60deg, rgba(2,0,36,1) 0%, rgba(18,45,45,1) 25%, rgba(15,30,41,1) 28%, rgba(15,30,41,1) 74%, rgba(18,45,45,1) 79%, rgba(15,30,41,1) 83%, rgba(15,30,41,1) 100%);
}

.tema-oscuro .btn.btn-secundary 
{
    color: rgb(143, 138, 136);
    color: var(--oscuro-grupos-sp);
}

.tema-oscuro .acceso-directo
{
	background-color: transparent;
	color: #c1b7b3;
}

.tema-oscuro .acceso-directo:hover
{
	color: var(--fondo-sp);
}

.tema-oscuro nav
{
	background-color: #0f1e29;
	background-color: var(--oscuro-fondo-blanco);

	box-shadow: 0px 0px 10px 0px #887c77;
    -o-box-shadow: 0px 0px 10px 0px #887c77;
    -webkit-box-shadow: 0px 0px 10px 0px #887c77;
    -moz-box-shadow: 0px 0px 10px 0px #887c77;
}

.tema-oscuro .fondo-sp
{
	background-color: #0f1e29;
	background-color: var(--oscuro-fondo-blanco);	
}

.tema-oscuro .buscador .fondo-blanco,
.tema-oscuro .aviso-inferior .text-white,
.tema-oscuro #Login .fondo-blanco,
.tema-oscuro .emparentados .fondo-blanco,
.tema-oscuro .buscador .fondo-blanco .buscador-seleccionado
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro input[type="text"],
.tema-oscuro input[type="date"],
.tema-oscuro input[type="time"],
.tema-oscuro input[type="number"],
.tema-oscuro input[type="password"],
.tema-oscuro select, 
.tema-oscuro textarea, 
.tema-oscuro input
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white;
}

.tema-oscuro .input-label
{
	color: rgb(100, 194, 194);
	color: var(--oscuro-fondo-sp);
}

.tema-oscuro .tipar-input span
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white;	
}

.tema-oscuro .tipar-input span svg
{
	color: white;	
}

.tema-oscuro label.ContainerCheck
{
	color: white;	
}

.tema-oscuro table tr:nth-child(even) 
{ 
	background: var(--filas-tabla-impar-sp); 
	color: white;
}

.tema-oscuro table tr:nth-child(even) input,
.tema-oscuro table tr:nth-child(even) select
{ 
	color: gray !important;
}

.tema-oscuro table tr:nth-child(odd) 
{
    background: rgb(81, 71, 68)!important;
    background: var(--oscuro-fondo-oscuro-sp)!important;
}

.tema-oscuro td.sticky-l
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white;	
}

.tema-oscuro .gradiente
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	background: #0f1e29 !important;
	background: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .pie-pagina
{
	border-top: gray solid 3px !important;
}

.tema-oscuro .fondo-pie
{
	background-color: #0f1e29 I !important;
	background-color: var(--oscuro-fondo-blanco) I !important;
}

.tema-oscuro .pie-pagina .borde-blanco
{
	border: 0 !important;
}

.tema-oscuro .Cabecera
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	border-bottom: gray solid 1px;
}

.tema-oscuro .dropdown-menu
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	border: 1px solid white;
}

.tema-oscuro .dropdown-menu .dropdown-menu-h .fondo-sp
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .filtro-consulta.blanco
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .Paginas-Consultas, 
.tema-oscuro .Paginas-Consultas button
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white !important;
}

.tema-oscuro button.no-select
{
	color: white;
}

.tema-oscuro .btn-secundary
{
	color: white !important;
}

.tema-oscuro .fixed-right.slide-in-right,
.tema-oscuro .fixed-right.slide-out-right
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white !important;
}

.tema-oscuro .fixed-right.slide-in-right #achicar,
.tema-oscuro .fixed-right.slide-in-right #agrandar,
.tema-oscuro .fixed-right.slide-in-right #cerrar-slide
{
	background-color: rgb(81, 71, 68) !important;
	background-color: var(--oscuro-fondo-oscuro-sp) !important;	
	color: white !important;
}

.tema-oscuro table .gris
{
	background-color: rgb(81, 71, 68) !important;
	background-color: var(--oscuro-fondo-oscuro-sp) !important;
	color: white !important;
}

.tema-oscuro .logo-principal div span
{
	color: white !important;
}

.tema-oscuro .grilla-consulta.v-0
{
	background-color: transparent !important;
}

.tema-oscuro .grilla-consulta.v-1
{
	background-color: rgb(70, 70, 70) !important;
}

.tema-oscuro .grilla-consulta.v-2
{
	background-color: rgb(100, 100, 100) !important;
}

.tema-oscuro .grilla-consulta.v-3
{
	background-color: rgb(130, 130, 130) !important;
}

.tema-oscuro .grilla-consulta.v-4
{
	background-color: rgb(180, 180, 180) !important;
}

.tema-oscuro .grilla-consulta.v-5
{
	background-color: rgb(220, 220, 220) !important;
}

.tema-oscuro .grilla-consulta.v-6
{
	background-color: rgb(67, 193, 36) !important;
}

.tema-oscuro .grilla-consulta.v--0
{
	background-color: rgb(245, 99, 99) !important;
}

.tema-oscuro .text-center,
.tema-oscuro .text-left,
.tema-oscuro .text-right,
.tema-oscuro .subrayado,
.tema-oscuro h4,
.tema-oscuro h5,
.tema-oscuro h6
{
	color: white !important;
}

.tema-oscuro .hoja-a4,
.tema-oscuro .hoja-a4 .text-center,
.tema-oscuro .hoja-a4 .text-left,
.tema-oscuro .hoja-a4 .text-right
{
	color: black !important;
}

.tema-oscuro .text-gradient,
.tema-oscuro .text-degrade
{
	background: #333 !important;
	background: linear-gradient(45deg, var(--fondo-sp) 20%, var(--botones-sp) 50%, var(--fondo-sp) 70%) !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.tema-oscuro .listado-abm .input-abm:disabled 
{
	color: white;
    background-color: transparent !important;
}

.tema-oscuro .fila-seleccionada input.input-abm,
.tema-oscuro .fila-seleccionada select.input-abm
{
	background-color: transparent !important;
	border: 1px solid white !important;
	color: gray !important;
}

.tema-oscuro .input-control input,
.tema-oscuro .input-control textarea
{
	background-color: transparent !important;
	color: gray !important;
}

.tema-oscuro .Comandera-larga a,
.tema-oscuro .Comandera a
{
	color: gray !important;
}

.tema-oscuro #menu h4,
.tema-oscuro #menu h6 
{
	color: var(--fondo-claro-sp) !important;
}

.tema-oscuro .fondo-aux.fondo-blanco
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;	
}

.tema-oscuro ul li
{
	color: gray;
}

.tema-oscuro .menu-spg.inferior.fondo-sp,
.tema-oscuro ul li.inferior-abierto
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
	color: white !important;
}

.tema-oscuro .buscador .reg-encontrados .centrados
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .buscador .reg-encontrados h3
{
	background-color: transparent !important;
}

.tema-oscuro input.fondo-transparente
{ 
	background-color: transparent !important;
} 

.tema-oscuro .triangulo-blanco 
{
	border-color: transparent transparent #0f1e29 transparent;
	border-color: transparent transparent var(--oscuro-fondo-blanco) transparent;
}

.tema-oscuro .abm-detalle,
.tema-oscuro .letra-detalle-comprobante,
.tema-oscuro .detalle-comprobante 
{
	background-color: #0f1e29;
	background-color: var(--oscuro-fondo-blanco);
}

.tema-oscuro .usuario-gr,
.tema-oscuro .usuario-gr h4,
.tema-oscuro .usuario-gr h6 
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .reportes, .reportes-emb 
{
	background-color: #0f1e29 !important;
	background-color: var(--oscuro-fondo-blanco) !important;
}

.tema-oscuro .server-pegado
{
	border: 2px solid #CCC;
	background-color: #0f1e29;
	background-color: var(--oscuro-fondo-blanco);
	color: #FFF;
} 

.tema-oscuro .hoja-a4 h1, .hoja-a4 h2, .hoja-a4 h3, .hoja-a4 h4, .hoja-a4 h1, .hoja-a4 h5, .hoja-a4 h6, .hoja-a4 h7
{
	color: black !important;
}

.tema-oscuro table tr td.fondo-blanco
{
	background: inherit !important;
}

.tema-oscuro .botones-confirmar .btn-secundary 
{
	color: black !important;
}
