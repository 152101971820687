@media screen and (max-width: 740px)
{
    .grilla-consulta
    {
        width: 5px !important;
        margin: 1px 0 0 1px;
        padding-left: 2px;
        padding-right: 2px;
    }

    .Contenedor
    {
        padding: 40px 10px 0 10px;
    }

    nav
    {
        position: fixed;
        left: 0;
        top: 0;
        height: 40px; 
        overflow-y: hidden;
    }

    nav:hover 
    {
        width: 85%;
    }

    .inferior
    {
        display: none; 
    }

    nav .superior
    {
        display: block;
    }

    .inferior.inferior-abierto
    {
        display: block;
        width: 84%;
    }

    .Cabecera
    {
        text-align: center;
        vertical-align: middle;
        line-height: 25px;
        font-size: 13px;
        margin-left: 0;
        padding-left: 45px;
    }

    .__react_component_tooltip.show
    {
        display: none !important;
    }

    .flotante
    {
        width: 350px;
        min-width: 350px;
        
        left: calc(50% - 175px);
        -o-left: calc(50% - 175px);
        -ms-left: calc(50% - 175px);
        -moz-left: calc(50% - 175px);
        -webkit-left: calc(50% - 175px);
        
        top: calc(50% - 300px); 
        -o-top: calc(50% - 300px); 
        -ms-top: calc(50% - 300px); 
        -moz-top: calc(50% - 300px); 
        -webkit-top: calc(50% - 300px); 
    }

    .flotante-chico
    {
        width: 320px;
        min-width: 320px;

        left: calc(50% - 160px); 
        -o-left: calc(50% - 160px); 
        -ms-left: calc(50% - 160px); 
        -moz-left: calc(50% - 160px); 
        -webkit-left: calc(50% - 160px);

        top: calc(50% - 200px); 
        -o-top: calc(50% - 200px); 
        -ms-top: calc(50% - 200px); 
        -moz-top: calc(50% - 200px); 
        -webkit-top: calc(50% - 200px); 
    }

    .logo-principal img 
    {
        margin: 10px;
    }

    .logo-principal h1
    {
	    margin-top: 6px;
	    margin-right: 20px;

        font-size: 10px;
        letter-spacing: 4px;

        padding-right: 0;
    }

    .logo-principal h1 span
    {
        font-size: 10px;
        letter-spacing: 1px;
    }

    .logo-principal h1 p
    {
        font-size: 8px;
        letter-spacing: 0;
    }

    .contenedor-accesos-directos
    {
        padding: 40px 10px 0 5px;
        text-align: center;
    }

    .acceso-directo
    {
        width: 130px;
        font-size: 30px;
        min-height: 150px;
        max-height: 150px;
    }

    .acceso-directo .tarjeta-anuncios 
    {
        padding: 5px !important; 
        max-height: 125px !important;
        min-height: 110px !important;
    }

    .dropdown-menu
    {
        left: 50px;
    }

    .botones-confirmar 
    {
        left: 0;
    }

    .buscador .reg-encontrados .centrados,
    .buscador .reg-encontrados .centrados.grande
    {
        width: 90%;
        height: 91%;
        left: 5%;
        top: 6%;
    }

    .buscador .icono-productos
    {
        width: 90px;
    }

    .buscador .codigo-buscador
    {
        width: 0;
    }

    .buscador .dropdown-menu
    {
        margin-top: -24px;
        margin-left: -200px;
        width: inherit;
    }

    .tipar-input select.col-xs-10 
    {
        width: 83.33333333% !important;
    } 

    .input-control input[type="date"]
    {
        background-color: white;
    }

    .buscador input.descrip 
    {
        width: 100%;
    }

    .buscador input.codigo 
    {
        width: 100%;
    }

    .detalle-fp-lista
    {
        padding: 0 !important;
    }

    .scroll-h
    {
        overflow-x: scroll;
        padding-bottom: 0 !important;
    }

    .scroll-v
    {
        overflow-y: scroll;
    }

    .scroll-h::-webkit-scrollbar-track,
    .scroll-v::-webkit-scrollbar-track  
    {
        box-shadow: inset 0 0 3px rgba(200, 200, 200, 0);
    }

    .scroll-h::-webkit-scrollbar,
    .scroll-v::-webkit-scrollbar  
    {
        width: 3px !important;
    }

    .buscador-abm
    {
        padding-left: 15px;
    }
    
    .abm-detalle
    {
        margin-right: 5px;
        padding-bottom: 10px;
    }
    
    .abm-detalle div.foto-abm-detalle
    {
        min-height: inherit;
    }

    .pie-pagina ul,
    .pie-pagina .logo-pie
    {
        padding-left: 0;
    }

    .filtro-consulta,
    .admin-producto,
    .comprobante-cabecera
    {
        margin-left: 7px;
        margin-right: 2px;
    }

    .letra-detalle-comprobante
    {
        min-height: 220px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
    .letra-detalle-comprobante img
    {
        width: auto;
        height: 50px;
        
        -webkit-left: calc(50% - 109px);
        -moz-left: calc(50% - 109px);
        -ms-left: calc(50% - 109px);
        -o-left: calc(50% - 109px);
        left: calc(50% - 109px);
    }

    .datos-detalle-comprobante span 
    {
        display: block;
        width: 100%;
    }
    
    .datos-detalle-comprobante h1,
    .datos-detalle-comprobante h2,
    .datos-detalle-comprobante h3,
    .datos-detalle-comprobante h4,
    .datos-detalle-comprobante h5,
    .datos-detalle-comprobante h6
    {
        line-height: 1;
    }

    .datos-detalle-comprobante.anulado
    {
        -webkit-top: calc(100% - 415px);
        -moz-top: calc(100% - 415px);
        -ms-top: calc(100% - 415px);
        -o-top: calc(100% - 415px);
        top: calc(100% - 415px);

        left: 10%;
        width: 80%;
    }

    .datos-detalle-comprobante.cerrado
    {
        -webkit-top: calc(100% - 250px);
        -moz-top: calc(100% - 250px);
        -ms-top: calc(100% - 250px);
        -o-top: calc(100% - 250px);
        top: calc(100% - 250px);

        left: 10%;
        width: 80%;
    }
        
    .collapse .card-body
    {
        padding: 1rem 1rem 1rem 0.2rem;
    }
    
    .tarjeta
    {
        margin-left: 15px;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 20px;
    }

    .sticky-l
    {
        position: relative;
        left: inherit;
        z-index: inherit;
    }

    .sticky-l-115
    {
        position: relative;
        left: inherit;
        z-index: inherit;
    }

    .sticky-l-160
    {
        position: sticky;
        left: 0;
        z-index: 10;
        max-width: 100px;
        overflow: hidden;
    }

    .versiones
    {
        padding-left: 30px;
    }
    
    .type-success 
    {
        max-width: 300px;
    }

    .fixed-right 
    {
        width: 75%;
    }

    .fs-total
    {
        font-size: 1.45rem !important;
    }

    .text-xs-center
    {
        text-align: center !important;
    }

    .text-xs-right
    {
        text-align: right !important;
    }

    .px-xs-0
    {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .px-xs-1
    {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-xs-2
    {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-xs-3
    {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-xs-4
    {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-xs-5
    {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .pl-xs-0
    {
        padding-left: 0 !important;
    }

    .pr-xs-0
    {
        padding-right: 0 !important;
    }

    .mt-xs-0
    {
        margin-top: 0 !important;
    }

    .mb-xs-0
    {
        margin-bottom: 0 !important;
    }

    .mt-xs-1
    {
        margin-top: 1rem !important;
    }

    .mt-xs-4
    {
        margin-top: 2.1rem !important;
    }

    .mb-xs-1
    {
        margin-bottom: 1rem !important;
    }

    .mb-xs-2
    {
        margin-bottom: 0.5rem !important;
    }

    .fixed-right.alargado 
    {
        max-width: calc(100% - 62px) !important;
        -o-max-width: calc(100% - 62px) !important;
        -ms-max-width: calc(100% - 62px) !important;
        -moz-max-width: calc(100% - 62px) !important;
        -webkit-max-width: calc(100% - 62px) !important;
    }

    .tab
    {   
        display: block;
        margin-bottom: 5px;
    }

    .tab-sel
    {
        border-radius: 5px;
        border: white 1px solid; /*var(--fondo-claro-sp)*/
    }

    .ml-xs-0
    {
        margin-left: 0 !important;
    }

    .mx-xs-0
    {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .absolute-bl
    {
        position: relative;
    }

    .absolute-br
    {
        position: relative;
    }

    .absolute-tl
    {
        position: relative;
    }

    .absolute-tr
    {
        position: relative;
    }

    .p-xs-0
    {
        padding: 0 !important;
    }

    .tarjeta-anuncios
    {
        width: 100%;
        min-height: 110px;
        padding: 15px 10px 10px 10px !important;
    }

    .tarjeta-anuncios.height-auto
    {
        min-height: inherit;
    }

    .aviso-inferior div
    {
        padding: 9px 10px 8px 10px;
    }

    .aviso-inferior span
    {
        display: block;
    }
    
    .ctacte-sp
    {
        padding-left: 0;
    }

    .ctacte-sp #datos-pagar ul
    {
        padding-left: 0;
    }

    .ctacte-sp #datos-bb,
    .ctacte-sp #datos-bc,
    .ctacte-sp #datos-otr
    {
        margin-left: 0 !important;
    }

    .text-grande
    {
        font-size: 2rem;
    }

    .text-esp-1-md
    {
        letter-spacing: inherit;
    }

    .text-esp-2-md
    {
        letter-spacing: inherit;
    }

    .text-esp-3-md
    {
        letter-spacing: inherit;
    }

    .text-esp-4-md
    {
        letter-spacing: inherit;
    }

    .text-esp-5-md
    {
        letter-spacing: inherit;
    }

    .tarjetas-pagar
    {
        margin-top: 0;
    }

    .reportes
    {
        width: 100vw;
        margin-left: -9px;
        padding: 0;

        overflow: scroll;
    }

    .xs-invisible,
    .invisible-xs
    {
        display: none;
    }
   
    .md-invisible,
    .invisible-md
    {
        visibility: visible !important;
    }
    
    .xs-h-50-vh
    {
        height: 50vh !important;
    }

    .justify-content-sm-start, 
    .justify-content-xs-start 
    {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end, 
    .justify-content-xs-end 
    {
        justify-content: flex-end !important;
    }
    
    .flex-sm-column,
    .flex-xs-column
    {
        flex-direction: column !important;
    }

    .min-xs-height,
    .min-sm-height-145
    {
        min-height: 145px !important;
    }

    .min-xs-height,
    .min-sm-height-100
    {
        min-height: 100px !important;
    }

    .min-xs-height,
    .min-sm-height-200
    {
        min-height: 200px !important;
    }

    .wpx-xs-10,
    .wpx-sm-10
    {
        width: 10px !important;
        height: inherit;
    }

    .wpx-xs-20,
    .wpx-sm-20
    {
        width: 20px !important;
        height: inherit;
    }

    .wpx-xs-25,
    .wpx-sm-25
    {
        width: 25px !important;
        height: inherit;
    }

    .wpx-xs-30,
    .wpx-sm-30
    {
        width: 30px !important;
        height: inherit;
    }

    .wpx-xs-50,
    .wpx-sm-50
    {
        width: 50px !important;
        height: inherit;
    }

    .wpx-xs-75,
    .wpx-sm-75
    {
        width: 75px !important;
        height: inherit;
    }

    .wpx-xs-100,
    .wpx-sm-100
    {
        width: 100px !important;
        height: inherit;
    }

    .wpx-xs-120,
    .wpx-sm-120
    {
        width: 120px !important;
        height: inherit;
    }

    .wpx-xs-150,
    .wpx-sm-150
    {
        width: 150px !important;
        height: inherit;
    }

    .wpx-xs-170,
    .wpx-sm-170
    {
        width: 170px !important;
        height: inherit;
    }

    .wpx-xs-200,
    .wpx-sm-200
    {
        width: 200px !important;
        height: inherit;
    }

    .wpx-xs-250,
    .wpx-sm-250
    {
        width: 250px !important;
        height: inherit;
    }

    .wpx-xs-300,
    .wpx-sm-300
    {
        width: 300px !important;
        height: inherit;
    }

    .wpx-xs-350,
    .wpx-sm-350
    {
        width: 350px !important;
        height: inherit;
    }

    .wpx-xs-400,
    .wpx-sm-400
    {
        width: 250px !important;
        height: inherit;
        overflow-x: hidden;
    }

    .hpx-xs-10,
    .hpx-sm-10
    {
        height: 10px !important;
        width: inherit;
    }

    .hpx-xs-20,
    .hpx-sm-20
    {
        height: 20px !important;
        width: inherit;
    }

    .hpx-xs-25,
    .hpx-sm-25
    {
        height: 25px !important;
        width: inherit;
    }

    .hpx-xs-30,
    .hpx-sm-30
    {
        height: 30px !important;
        width: inherit;
    }

    .hpx-xs-50,
    .hpx-sm-50
    {
        height: 50px !important;
        width: inherit !important;
    }

    .hpx-xs-100,
    .hpx-sm-100
    {
        height: 100px !important;
        width: inherit;
    }

    .hpx-xs-120,
    .hpx-sm-120
    {
        height: 120px !important;
        width: inherit;
    }

    .hpx-xs-150,
    .hpx-sm-150
    {
        height: 150px !important;
        min-height: 150px !important;
        max-height: 150px !important;
    }

    .hpx-xs-170,
    .hpx-sm-170
    {
        height: 170px !important;
        width: inherit;
    }

    .hpx-xs-180,
    .hpx-sm-180
    {
        height: 180px !important;
        min-height: 180px !important;
        max-height: 180px !important;
    }

    .hpx-xs-200,
    .hpx-sm-200
    {
        height: 200px !important;
        min-height: 200px !important;
        max-height: 200px !important;
    }

    .Cabecera-titulo
    {
        font-size: 8px;
        text-align: right;
        line-height: 15px;
    }

    .usuario-gr h4,
    .usuario-gr h6
    {
        max-width: 350px;
    }

    .text-xs-input-small input
    {
        font-size: 12px !important;
        height: 15px !important;
        max-height: 15px !important;
        min-height: 15px !important;
    }

    .detalle-prod .opciones-stock .text-nowrap
    {
        text-wrap: wrap !important;
    }

    .fondo-degrade-gray-t
    {
        background: rgb(170,170,170);
        background: linear-gradient(180deg, rgba(170,170,170,1) 6%, rgba(0,212,255,0) 13%, rgba(0,0,0,0) 100%);
    }
}