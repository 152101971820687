@media screen and (max-width: 800px)
{
    .flex-sm-column,
    .flex-xs-column
    {
        flex-direction: column !important;
    }

    .principal .informes
    {
        flex: 0 0 100% !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}